/* 해당 파일과 SubTitle 컴포넌트를 삭제하고 글로벌로 사용하는 것으로 변경해야함 */
.wrapper {
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.wrapper img {
  width: 10px;
}

.subtitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.5;
  color: #024c8c;
}

@media screen and (max-width: 640px) {
  .wrapper {
    padding-bottom: 20px !important;
  }
  .subtitle {
    font-size: 16px;
  }
}
