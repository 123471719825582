.main_notice_wrapper {
    max-width: 1100px;
    /*margin: 258px auto 100px;*/
    margin: 0 auto;
    padding: 100px 0;
}

.main_notice_wrapper .notice_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main_notice_wrapper .title {
    color: var(--brand-1);
    font-size: 24px;
    font-weight: 600;
    display: flex;
    gap: 17px;
    align-items: center;
}



.notice_card {
    background-color: #fff;
    border-radius: 32px;
    padding: 32px;
    box-shadow: 0 4px 30px 0 rgba(174, 193, 208, 0.20);
    margin-top: 52px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    cursor: pointer;
    height: 240px;
}

.notice_card .notice_sub_title {
    display: flex;
    gap: 18px;
    align-items: center;
    font-size: 16px;

}

.notice_sub_title .badge {
    padding: 4px 18px;
    background-color: var(--brand-5);
    color: var(--brand-1);
    border-radius: 20px;
    line-height: 1.5;
}

.notice_sub_title .date {
    color: var(--brand-1);
}

.notice_card .notice_title {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
}

.notice_card .content {
    line-height: 24px;
    font-size: 16px;
    color: var(--gray-3);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.notice_pagination {
    display: flex;
    gap: 14px;
    justify-content: flex-end;
    align-items: center;
}

.notice_pagination .swiper_page {
    display: flex;
    gap: 3px;
    color: var(--gray-4);
}

.notice_pagination .swiper_page .current {
    width: 10px;
}

.main_notice_wrapper .notice_top_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notice_top_box .nav_btn {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    color: var(--brand-1);
}

.notice_top_box .nav_btn img {
    width: 8px;
    height: 12px;
    display: block;
}

.main_notice_wrapper .swiper {
    overflow: visible !important;
}

.play_status {
    display: flex;
}

.play_status img {
    display: block;
}

@media screen and (max-width: 1280px) {
    .main_notice_wrapper {
        width: 90%;
        padding: 60px 0;
    }

    .notice_card {
        padding: 20px 24px;
        border-radius: 20px;
    }
}
