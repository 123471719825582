.eduModal_container > span {
  font-size: 16px;
}

.eduModal_container > h3 {
  color: rgba(2, 76, 140, 1);
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-top: 5px;
  margin-bottom: 12px;
}

.eduModal_container .subTitle {
  display: flex;
  gap: 9px;
  font-size: 16px;
  color: #333333;
  margin-bottom: 20px;
  line-height: 1.5;
}
.edu_card_modal_professor_name {
  font-weight: 600;
}
.edu_card_modal_join_professor_cnt {
  cursor: pointer;
  font-weight: 600;
  border-bottom: 1px solid #000;
}
.eduModal_contents {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  flex-direction: column;
}
.edu_card_modal_divider {
  width: 100%;
  border-bottom: 1px solid #D9D9D9;
  margin: 30px 0 32px 0;
}
.eduModal_contents .studentInfo .studyPeriod {
  display: flex;
  align-items: center;
  gap: 8px;
}

.eduModal_contents .studyPeriod > p {
  color: #024c8c;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.CompletionCriteria {
  display: flex;
  flex-direction: column;
  margin-bottom: 52px;
}

.CompletionCriteria .description_box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.CompletionCriteria .description_box:first-of-type {
  margin-bottom: 34px;
}

.CompletionCriteria .description_box dt {
  width: 70px;
  color: #2e2e2e;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-right: 52px;
}

.CompletionCriteria .description_box dd {
  font-weight: 400;
  color: #333333;
  font-size: 16px;
  line-height: 24px;
}
.CompletionCriteria .description_box dd ul li {
  list-style-type: "- ";
}
.CompletionCriteria .description_box dd ul li:last-of-type {
  list-style-type: none;
  margin-left: -10px;
}

.eduModal_contents .studentInfo {
  position: relative;
}

.eduModal_contents .studentInfo > button {
  border: none;
  font-size: 16px;
  color: rgb(255, 255, 255);
  padding: 11px 32px;
  border-radius: 6px;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.eduModal_contents .studentInfo > button.modal_disabled_btn {
  background-color: var(--brand-1);
}

.eduModal_contents .studentInfo > button.modal_apply_btn {
  background-color: var(--gray-6);
}

@media screen and (max-width: 1280px) {
  .eduModal_container {
  }

  .eduModal_container > h3 {
    font-size: 20px;
  }

  .studyPeriod > span {
    letter-spacing: -0.6px;
  }

  .CompletionCriteria .description_box {
    flex-direction: column;
    gap: 4px;
  }

  .CompletionCriteria .description_box dd {
    font-size: 14px;
  }

  .EducationChasi .category_header {
    padding: 20px !important;
  }
}

@media screen and (max-width: 640px) {
  .eduModal_contents .studentInfo > button.modal_disabled_btn {
    width: 100%;
    position: static;
    margin-top: 12px;
  }

  .CompletionCriteria .description_box dt {
    /* 수료 기준 , 수료증 워딩*/
    font-size: 14px;
    position: relative;
    margin-left: 14px;
    color: #024c8c;
  }
  .CompletionCriteria .description_box dd ul li {
    list-style-type: none;
  }
  .CompletionCriteria .description_box dd ul li:last-of-type {
    margin-left: 0;
  }

  .CompletionCriteria .description_box dd ul li span {
    font-weight: 600;
    display: block;
    color: #333;
  }
  .CompletionCriteria .description_box dt::before {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -14px;
    background-color: #024c8c;
    width: 6px;
    height: 6px;
    border-radius: 6px;
  }
}
