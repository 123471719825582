/*

존재하는 테마

"mini" = header 색 안칠해진 미니모달

Modal 컴포넌트에서
props -> includesClassName에 테마 이름을 추가하면 된다.

 */

.OverlaySection {
  position: fixed;
  z-index: 200;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 100px 54px;

  &.mini {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &.full_auto_mode {
    padding: 100px 54px;
    top: 0;
  }
}

.modal_container {
  z-index: 3000;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  /* background-color: white; */
  border-radius: 10px;

  /* 미니모달 */
  &.mini {
    border-radius: 16px;
  }

  /* 컨펌 모달 */
  &.confirm {
    border-radius: 16px;
    width: 468px;
  }

  /* 화면 최대 높이 에 맞게 자동으로 지정 */
  &.full_auto_mode {
    width: 888px;
    height: 100%;
    min-height: 100%;
  }

  /* 높이 800까지 자동 조절 */
  &.max_800_auto_mode {
    width: 888px;
    max-height: 800px;
    height: 100%;
  }

  /* 프로그램 정보 모달 */
  &.course_info {
    width: 800px;
    max-height: 800px;
    height: 100%;
  }

  /* 교수자 찾기 모달 */
  &.find_professor {
    width: 595px;
    max-height: 703px;
    height: 100%;
  }
}

/* 모달 해더 */
.modal_header {
  position: relative;
  box-sizing: border-box;
  background-color: #024c8c;
  width: 100%;
  border-radius: 10px 10px 0 0;
  padding: 12px 24px;
  /*padding: 12px 24px 12px 54px;*/

  &.mini {
    border-radius: 16px 16px 0 0;
    background-color: white;
    height: auto;
    padding: 32px 32px 0 32px;
  }

  &.confirm {
    border-radius: 16px 16px 0 0;
    background-color: white;
    height: auto;
    padding: 20px 20px 0 20px;
  }
}

.modal_header_inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.modal_title {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;

  &.mini {
    color: var(--gray-1);
    font-size: 16px;
    font-weight: 400;
    padding: 0 16px 0 0;
    line-height: 1.5;

    &.text_answer_total_view {
      font-weight: 600;
    }
  }

  &.confirm {
    color: var(--gray-1);
    font-size: 20px;
    font-weight: 400;
  }
}

/* 모달 컨텐츠 */
.modal_content_wrapper {
  padding: 2px 2px;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  max-height: 800px;

  /* 미니모달 */
  &.mini,
  &.confirm {
    border-radius: 0 0 16px 16px;
  }

  /* 화면 최대 높이 에 맞게 자동으로 지정 */
  &.full_auto_mode {
    height: 100%;
    min-height: calc(100% - 54px);
  }

  /* 높이 800까지 자동 조절 */
  &.max_800_auto_mode {
    max-height: 800px;
    height: 100%;
  }

  /* 프로그램 정보 모달 */
  &.course_info {
    height: 100%;
    max-height: 800px;
  }

  /* 교수자 찾기 모달 */
  &.find_professor {
    height: calc(100% - 56px);
  }
}

.modal_content {
  padding: 32px;
  max-height: 100%;

  /* 미니모달 */
  &.mini {
    padding: 0 32px 32px 32px;
  }

  &.confirm {
    padding: 0 20px 20px 20px;
  }

  /* 화면 최대 높이 에 맞게 자동으로 지정 */
  &.full_auto_mode {
    padding: 32px;
    height: 100%;
  }

  /* 높이 800까지 자동 조절 */
  &.max_800_auto_mode {
    height: 100%;
    max-height: calc(800px - 4px);
  }

  /* 교수자 찾기 모달 */
  &.find_professor {
    height: 100%;
  }
}

.modal_close_button {
  cursor: pointer;
  stroke: white;
  min-width: 20px;
  min-height: 20px;

  &.mini,
  &.confirm {
    position: absolute;
    top: 0;
    right: 0;
    stroke: var(--gray-1);
    min-width: 14px;
    min-height: 14px;
  }
}

@media screen and (max-width: 1024px) {
  .OverlaySection {
    top: 60px;
    padding: 40px 16px;
    height: calc(100% - 60px);

    &.mini {
      padding: 40px 16px !important;
      background-color: rgba(0, 0, 0, 0.3);
    }

    &.full_auto_mode {
      padding: 20px 16px;
      top: 60px;
    }

    /* 높이 800까지 자동 조절 */
    &.max_800_auto_mode {
      padding: 20px 16px;
      top: 60px;
    }
  }

  /* 모달 해더 */
  .modal_header {
    padding: 6px 20px;

    &.mini {
      border-radius: 16px 16px 0 0;
      background-color: white;
      height: auto;
      padding: 32px 32px 0 32px;
    }
  }

  .modal_content_wrapper {
    height: calc(100% - 42px) !important;
    min-height: calc(100% - 60px) !important;

    &.mini {
      height: calc(100% - 60px) !important;
    }
  }

  .modal_content {
    height: 100%;
    padding: 24px;

    &.mini {
      padding: 0 32px 32px 32px;
      border-radius: 0 0 16px 16px;
    }

    &.full_auto_mode {
      height: 100%;
      padding: 28px 24px;
    }

    /* 높이 800까지 자동 조절 */
    &.max_800_auto_mode {
      height: 100%;
      padding: 28px 24px;
    }

    &.find_professor {
      height: 100%;
      padding: 28px 24px;
    }
  }

  .modal_container {
    overflow: auto;
    width: 100% !important;
    height: 100%;
    border-radius: 10px;

    &.mini {
      height: 442px;
    }
  }
}