.modalSubTitle h2 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 40px;
}

.modalSubTitle p {
  color: var(--brand-1);
  font-weight: 500;
  margin-bottom: 20px;
}

.modalContent {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  gap: 20px;
  margin: 3cap auto;
}

.modalContent h3 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.modalInput {
  display: flex;
  gap: 10px;
  width: 100%;
  padding: 15px;
  margin: 20px auto 0;
  border: var(--blue-gray-2) 1px solid;
  border-radius: 4px;
  font-size: 14px;
  background-color: var(--blue-gray-2);
  font-size: 14px;
  resize: none;
}

.modalInput::placeholder {
  color: var(--blue-gray-1);
}

.modalContentList {
  padding-bottom: 20px; /* 하단 여백 추가 */
}

.hiddenFileInput {
  display: none;
}

.modal_preview_button {
  display: flex;
  position: relative;
  top: 10px; /* 위쪽 간격 */
  display: flex;
  align-items: center;
}

.modal_preivew_button_item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  color: var(--brand-1);
  background-color: transparent;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.modal_preivew_button_item:hover {
  background-color: var(--blue-gray-2);
}

.modal_preivew_button_item p {
  margin: 0;
  display: flex;
  align-items: center;
}

.performance_previous_button {
  padding: 13px 27px;
  cursor: pointer;
  border: var(--blue-gray-1) 1px solid;
  border-radius: 5px;
}

.performance_next_button {
  padding: 13px 27px;
  cursor: pointer;
  border: var(--blue-gray-1) 1px solid;
  border-radius: 5px;
  margin-left: auto;
  justify-content: flex-end;
}

.performance_previous_button:hover {
  background-color: var(--blue-gray-2);
}

.performance_next_button:hover {
  background-color: var(--blue-gray-2);
}

.modalActions {
  margin-top: 20px;
  margin-bottom: 30px; /* 하단 여백 추가 */
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
