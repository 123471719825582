/* 넘버 스타일 */
.custom_swiper_pagination_number_style {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: white;
}

.custom_swiper_pagination_number_style button {
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}


/* 클릭 스타일 */

/* 페이지네이션 */
.custom_swiper_pagination_click_style {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 6px;
}

.custom_swiper_pagination_click_style button {
  margin: 0 5px;
  width: 6px;
  height: 6px;
  background-color: rgba(255, 255, 255, 0.5);
  color: var(--brand-1);
  cursor: pointer;
  border: none;
  border-radius: 6px;
}

.custom_swiper_pagination_click_style button.active {
  background-color: rgba(255, 255, 255, 1);
}

.custom_swiper_pagination_click_style button:hover {
  background-color: #0056b3;
}