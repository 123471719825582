/* MainQuickMenu -----------------------*/
.quickMenuContainer {
  position: fixed;
  padding: 42px 32px 20px 32px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0px 4px 30px 0px rgba(102, 117, 130, 0.2);
  border-radius: 20px 0 0 20px;
  background-color: #fff;
  /* transition: all 0.6s ease-out; */
  transition: all 0.5s ease-in-out;
}

.quickMenuContainer.on {
  top: 50% !important;
  transform: translateY(-50%);
}

.quickMenuContainer:hover {
  box-shadow: 0px 4px 30px 0px rgba(102, 117, 130, 0.4);
}

.quickMenuList {
  /* width: 180px; */
  display: flex;
  flex-direction: column;
  gap: 24px;
  z-index: 2;
}

.quickMenuList > li {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 4px;
}
.quickMenuList > li::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  display: block;
  height: 2px;
  background-color: var(--brand-1);
  border-radius: 4px;
  width: 0;
  transition: all 0.2s ease-in-out;
}
.quickMenuList > li:hover::after {
  width: 100%;
}
.quickMenuList > li:hover .quickMenuName {
  color: #333;
  font-weight: 500;
}

.quickMenuList .quickMenuName {
  margin-left: 12px;
  color: var(--gray-3);
}

.quickMenuButton > button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -25px;
  border: 0;
  font-size: 0;
  background-color: transparent;
  cursor: pointer;
  width: 42px;
  height: 42px;
  background-color: #aaafb9;
  border-radius: 100%;
  transition: all 0.2s ease-in-out;
}
.quickMenuButton:hover > button {
  background-color: var(--brand-1);
}

.quickMenuButton .quickMenuImage {
  transition: all 0.6s ease; /* transform에 대한 transition 추가 */
}

.quickMenuButton:hover svg {
  transform: rotate(0);
}

.quickMenuButton:hover svg.rotated {
  transform: rotate(180deg);
}

.quickMenuButton svg {
  transform: rotate(180deg);
}
.quickMenuButton svg.rotated {
  transform: rotate(0);
}

.quickMenuContainer.visible {
  right: 0;
}
.quickMenuContainer.hide {
  top: 50% !important;
  right: -190px;
}

.topMainContainerWrap {
  overflow: hidden;
}

@media screen and (max-width: 1280px) {
  .quickMenuContainer {
    display: none;
  }
}
