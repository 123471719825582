#edu_project_join_professor {
    padding: 22px 12px 20px 24px;
}
.join_professor_tooltip {
    display: flex;
    max-width: 350px;
    flex-direction: column;
    max-height: 320px;
    overflow: auto;
    font-size: 14px;

    &::-webkit-scrollbar {
        width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: rgba(248, 249, 250, 0.2);
    }
}
.edu_card_modal_join_professor_tooltip_item {
     font-weight: 300;
     display: flex;
     flex-wrap: wrap;
     gap: 16px;
     color: rgba(255, 255, 255, 0.6);
     line-height: 1.5;
 }
.edu_card_modal_join_professor_tooltip_item_name {
    color: white;
}
.edu_card_modal_join_professor_tooltip_item_divider {
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 10px 0;
}