:root {
  --header-height: 60px; /* Header 높이 */
  --navbar-height: 50px; /* Navbar 높이 */
}

.research_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  flex-direction: column; /* 세로 정렬 */
  padding-bottom: 50px; /* 하단 여백 */
  max-width: 1320px;
  margin: 0 auto;
}

.research_wrapper .university_research_list_title_wrapper {
  text-align: left;
  margin-top: 80px;
  margin-bottom: 20px;
  position: relative;
}

.research_wrapper .btn_group_research_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.research_wrapper .university_research_list_title {
  font-size: 32px;
  line-height: 48px;
  font-weight: 600;
  color: var(--brand-1);
  display: flex;
  align-items: center;
}

.research_wrapper .university_research_list_total_items {
  margin-left: 30px;
  font-size: 18px;
}

/* .research_wrapper .search-filter-wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 30px;
  width: 100%;
} */

.research_wrapper .university_research_list_table_wrapper {
  width: 100%;
}

.research_wrapper .university_research_list_table_wrapper .university_research_list_table_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid var(--table-line-2); /* 헤더와 내용 구분선 */
  padding: 12px 0;
  /* font-weight: 600; */
  font-size: 15px;
  color: var(--gray-5);
}

.research_wrapper .university_research_list_table_wrapper .university_research_list_table_body {
  list-style: none;
  padding: 0;
  margin-bottom: 30px;
  color: var(--gray-2);
}

.research_wrapper .university_research_list_table_wrapper .researchItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--table-line-2);
  padding: 20px 0;
}

.research_wrapper .university_research_list_table_wrapper .research_no,
.research_wrapper .university_research_list_table_wrapper .researchNoValue,
.research_wrapper .university_research_list_table_wrapper .research_attach,
.research_wrapper .university_research_list_table_wrapper .researchFileValue,
.research_wrapper .university_research_list_table_wrapper .research_date,
.research_wrapper .university_research_list_table_wrapper .researchDateValue {
  flex: 0 0 15%;
  text-align: center;
  padding: 0 8px;
  font-weight: 200;
}

.research_wrapper .university_research_list_table_wrapper .research_title,
.research_wrapper .university_research_list_table_wrapper .researchTitleValue {
  flex: 1;
  text-align: left;
  font-weight: 500;
  padding: 0 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.research_wrapper .fileIconWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* 아이콘 크기 */
  height: 100%;
}

.research_wrapper .fileIcon {
  width: 24px;
  height: 24px;
  fill: var(--blue-gray-1); /* 아이콘 색상 */
  margin: auto;
}

/* 파일이 없을 경우 텍스트 스타일 */
.research_wrapper .noFileText {
  color: var(--gray-5); /* 회색 */
  font-size: 14px; /* 텍스트 크기 */
  text-align: center;
}

.research_wrapper .pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.research_wrapper .university_research_list_plusButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background-color: var(--brand-4);
  border-radius: 6px;
  cursor: pointer;
}

.university_research_list_add_upload_icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  padding: 10px;
  height: 35px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.university_research_list_add_upload_icon img {
  pointer-events: none; /* 이미지 자체에서 클릭 이벤트 차단 */
}

.research_wrapper .common_download_button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: #4caf50;
  color: #fff;
  padding: 10px 20px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.research_wrapper .common_download_button:hover {
  background-color: #45a049;
}

@media screen and (max-width: 1280px) {
  .research_wrapper {
    width: 90%;
  }
  .research_wrapper .titleBox {
    font-size: 18px;
  }
}

@media screen and (max-width: 640px) {
  .research_wrapper .university_research_list_title  {
    flex-direction: column;
    align-items: flex-start;
  }

  .research_wrapper .university_research_list_total_items {
    margin: 0;
  }

  .research_wrapper .GlobalSearchTextInput {
    margin: 0;
  }
}
