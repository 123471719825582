#header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 60px;
}
#header .header_inner {
  background: #fff;
  border-bottom: 1px solid #ccc;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#header h1 {
  position: relative;
  font-size: 0;
}

#header h1 a {
  display: block;
  background-image: url("@/assets/images/scout_logo.png");
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.3s all;
  background-size: cover;
  width: 109px;
  height: 42px;
}

#gnb .menu_box {
  display: flex;
  gap: 60px;
  justify-content: space-around;
  align-items: center;
}
#gnb .menu_box::after {
  content: "";
  clear: both;
  width: 0;
  display: block;
}

#gnb .menu_box > li > a:hover {
  color: #024c8c;
}

#gnb ul li a {
  position: relative;
  display: inline-block;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  z-index: 2;
  text-align: center;
  word-break: keep-all;
  white-space: nowrap;
}

#gnb .depth1 {
  padding: 20px 0px;
  position: relative;
  text-align: center;
}

.pc #gnb .depth1 ul {
  /* background-color: #44474b; */
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
  top: 80px;
  transition: opacity 0.3s ease-in-out, top 0.3s ease-in-out, background-color 0.3s ease-in-out;
  opacity: 0;
}

#gnb .depth1 .nav_bmc span {
  display: block;
  font-size: 14px;
  margin-top: 5px;
}

#gnb .depth1.event-hover:hover ul {
  background-color: #003b6d;
  height: auto;
  width: auto;
  top: 60px;
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
}

#gnb .depth1.event-hover:hover .depth2 {
  height: auto;
  width: 160px;
  opacity: 1;
}
#gnb .depth1 .depth2:first-child h4 {
  padding-top: 20px;
}
#gnb .depth1 .depth2:last-child h4 {
  padding-bottom: 20px;
}
#gnb .depth1 .depth2 a {
  cursor: pointer;
  border: none;
  background-color: transparent;
  position: relative;
  padding: 15px 0;
  color: white;
  word-break: keep-all;
  white-space: nowrap;
}
#gnb .depth1 .depth2 a::before {
  content: "";
  background: #fff;
  position: absolute;
  bottom: 0px;
  /* left: 50%;
  transform: translateX(-50%); */
  left: 0;
  height: 2px;
  border-radius: 4px;
  width: 0%;
  transition: all 0.2s ease-out;
}
#gnb .depth1 .depth2:hover > h4 > a::before {
  width: 100%;
}

#gnb .depth1 h3 a {
  padding: 10px 0;
}

#gnb .menu_box > li > h3 > a::before {
  content: "";
  background: #024c8c;
  position: absolute;
  bottom: 0;
  /* left: 50%;
  transform: translateX(-50%); */
  height: 2px;
  border-radius: 4px;
  width: 0%;
  transition: all 0.2s ease-out;
}
#gnb .menu_box > .depth1.event-hover:hover > h3 > a {
  color: #024c8c;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
}
#gnb .menu_box > .depth1.event-hover:hover > h3 > a::before {
  width: 100%;
}

/* 햄버거 메뉴 */
#gnb .btn_gnb_open {
  position: fixed;
  right: 20px;
  top: 15px;
  width: 30px;
  height: 30px;
  font-size: 0;
  display: none;
  text-indent: -9999%;
  z-index: 100;
}
#gnb .btn_gnb_open span {
  position: relative;
  display: block;
  width: 30px;
  height: 2px;
  margin: 14px auto 0;
  background: #333;
}
#gnb .btn_gnb_open span::before,
#gnb .btn_gnb_open span::after {
  content: "";
  display: block;
  width: 30px;
  height: 2px;
  position: absolute;
  left: 0;
  background: #333;
  transition: all 0.3s;
  z-index: 2;
}
#gnb.on .btn_gnb_open span::before,
#gnb.on .btn_gnb_open span::after {
  background: #fff;
}

#gnb .btn_gnb_open span::before {
  top: 9px;
}
#gnb .btn_gnb_open span::after {
  top: -9px;
}

/* 모바일 메뉴 헤더 */
.mobile_menu_header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

/* 로그인, 회원가입, 검색창, 유저이름 */
.search_auth_box {
  display: flex;
  gap: 20px;
  align-items: center;
}

.search_auth_box .userinfo_name {
  font-size: 16px;
}
.auth_box {
  padding: 0 8px;
  background-color: #f3fbff;
  border-radius: 40px;
  z-index: 100;
}
.auth_box ul {
  display: flex;
}
.auth_box ul li a {
  display: block;
  position: relative;
  padding: 10px 16px;
  word-break: keep-all;
  white-space: nowrap;
  color: #333;
  font-size: 16px;
  font-weight: 300;
}
.auth_box ul li:first-child a::after {
  content: "";
  display: block;
  width: 2px;
  height: 6px;
  background-color: #000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.seach_button {
  font-size: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 5px;
}

/* 검색창 - MainSearch - 컴포넌트 */
.mainSearchContainer {
  background-color: var(--blue-gray-2);
  width: 100%;
  display: flex;
  padding: 60px 100px;
  position: relative;
  top: -60px;
  left: 0;
  box-sizing: border-box;
}

.overlay {
  position: fixed;
  display: none;
  z-index: 999;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 반투명한 검은 배경 */
  width: 100%;
  height: 100%;
}

.overlay.open {
  opacity: 1;
  display: block;
}

.mainSearchLogo {
  background-image: url("@/assets/images/scout_logo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 114px;
  height: 44px;
  font-size: 0;
}

.mainSearch {
  width: 600px;
  margin: 0 auto;
}

.mainSearchTitle {
  color: var(--brand-1);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.popularKeywordsList {
  margin-top: 50px;
}

.popularKeywordsList span {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 24px;
  display: block;
}

.popularKewordsItems {
  display: flex;
  gap: 12px;
}

.popularKewordsItems li {
  border: 1px solid var(--brand-1);
  padding: 10px 17px;
  border-radius: 22px;
  color: var(--brand-1);
}

.searchInputContainer .searchFormBox {
  width: 100%;
  display: flex;
  position: relative;
  border-bottom: 1px solid var(--brand-3);
  margin-top: 36px;
}

.searchFormBox .searchInput {
  width: 100%;
  border: 0;
  background-color: transparent;
  padding: 20px 0;
  font-size: 20px;
  padding-left: 10px;
}

.searchFormBox .searchInput::placeholder {
  font-size: 20px;
  font-weight: 300;
  line-height: 36px;
  color: var(--gray-5);
}

.searchFormBox .searchButton {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.searchCloseButton {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  font-size: 0;
  position: absolute;
  top: 60px;
  right: 60px;
}

/* 모바일 서브 해더 */
#header.mobile #gnb .depth1 .sub_list {
  /* transition: all 0.2s ease-in-out; */
  transition: all 0.5s ease-out;
  overflow: hidden;
}

#header.mobile #gnb .depth1 .open {
  height: auto;
  opacity: 1;
  overflow: hidden;
}

#header.mobile #gnb .depth1 .hide {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

@media only screen and (max-width: 1280px) {
  #header {
    padding: 0;
    width: 100%;
  }
  #header h1 {
    margin-top: 0;
  }

  #header nav {
    justify-content: normal;
  }
  #header .header_inner {
    padding: 0 70px 0 20px;
    justify-content: space-between;
  }

  #gnb {
    position: fixed;
    top: 0;
    width: 80%;
    height: 100%;
    background: var(--brand-1);
    right: -100%;
    z-index: 20;
    overflow: auto;
    transition: right 0.3s ease-out;
  }
  #gnb.on {
    right: 0;
  }
  #gnb .menu_box {
    padding-top: 30px;
    padding-left: 54px;
    display: flex;
    gap: 0;
    align-items: flex-start;
    flex-direction: column;
    justify-content: normal;
    position: relative;
    flex-grow: 1;
  }

  #header nav ul .depth1 a {
    color: #fff;
    text-align: start;
    font-size: 20px;
  }

  #gnb .depth1 {
    padding: 0;
    width: 100%;
    text-align: start;
  }
  #gnb .depth1 ul {
    position: static;
    transform: none !important;
    background: none !important;
  }
  #gnb .depth1 h3 a {
    padding: 20px 0;
  }
  #gnb .depth1 .depth2:first-child h4 {
    padding: 0;
  }
  #gnb .depth1 .depth2:last-child h4 {
    padding: 0;
  }
  #gnb .depth1 .depth2 h4 a {
    padding-left: 12px;
    font-size: 16px;
    color: #c6e7ff;
    font-weight: 400;
  }

  .userinfo_name {
    display: none;
  }

  .modal_bg {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    opacity: 0;
  }

  #header nav.on .btn_gnb_open span {
    background: rgba(255, 255, 255, 0);
  }
  #header nav.on .btn_gnb_open span::before {
    top: 0;
    transform: rotate(45deg);
  }
  #header nav.on .btn_gnb_open span::after {
    top: 0;
    transform: rotate(-45deg);
  }
  #header nav .btn_gnb_open {
    display: block;
  }
  #header .menu_box > li > h3 > a::before {
    left: 0;
    transform: translate(0);
  }

  #gnb .menu_box > li:hover > h3 > a {
    background: none;
  }

  .header_auth_box_wrapper {
    position: relative;
    z-index: 1;
    font-size: 18px;
    padding-left: 54px;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 24px;
    color: white;
  }
  .header_auth_divider {
    width: 1px;
    height: 12px;
    background-color: white;
  }

  /* 모바일 로그인, 회원가입 */
  .menu_box_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .mobile_auth_box {
    margin-top: 3rem;
    position: relative;
    bottom: 10%;
    left: 50%;
    z-index: 31;
    transform: translateX(-50%);
  }

  .mobile_auth_box ul a {
    display: block;
    padding: 20px 0;
    color: #fff;
  }

  /* pc 검색 , pc 로그인 가리기  */
  .auth_box {
    display: none;
  }

  .seach_button {
    display: none;
  }
  /*  */
  .search_auth_box {
    display: none;
  }
}

/* 모바일 */
@media screen and (max-width: 450px) {
}
