/* 수업  커리큘럼 */
.EducationChasi {
  margin-bottom: 60px;

  /* 프로그램 개설 버전 */
  &.course_ver {
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.EducationChasi h3 {
  font-size: 32px;
  font-weight: 500;
  color: #333;
  margin-bottom: 20px;
}

.EducationChasi h4 {
  color: #333;
  font-size: 20px;
  line-height: 1.5;
  font-weight: 700;
}

/* 카테고리 */
.EducationChasi .education_list_wrapper {
  border: 1px solid #e8f0f3;
  border-radius: 8px 8px 0 0;
  margin-top: 90px;

  /* 프로그램 개설 버전 */
  &.course_ver {
    margin-top: 48px;
  }
}

.EducationChasi .education_category_list > dd {
  transition: all 0.2s ease;
  overflow: hidden;
  height: auto;
}
.EducationChasi .education_category_list.on > dd {
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
}

.EducationChasi .category_header {
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px 8px 0 0;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 32px;
  padding-right: 28px;
  border-bottom: 1px solid #e8f0f3;
}

.EducationChasi .category_header .edu_header_left {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.EducationChasi .category_header .icon_folder {
  width: 32px;
}
.EducationChasi .category_header .btn_accordion img {
  width: 24px;
}

/* 차시 목록 */
.EducationChasi .chasi_item {
  padding: 20px 18px 20px 36px;
  border-bottom: 1px solid #e8f0f3;
  position: relative;
}

.EducationChasi .chasi_item:last-child {
  border: none;
}

.EducationChasi .chasi_list_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* 마진 바텀 주석처리한 이유 : 과제가 없을때 마진이 들어가면 안됨 -> 모달 리스트 바디에 마진 탑 - 하연*/
  /* margin-bottom: 24px; */
  /* border: 3px solid green; */
}

.EducationChasi .chasi_list_header .chasi_list_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* border: 2px solid red; */
}

.EducationChasi .chasi_list_header > div {
  display: flex;
}
/* test */
.EducationChasi .chasi_list_header span {
  display: inline-block;
}

.EducationChasi .chasi_list_header .chasi_list_title > div {
  display: flex;
}

.EducationChasi .chasi_list_header .chasi_list_title .chasi {
  position: relative;
  font-size: 16px;
  line-height: 24px;
  color: var(--brand-1);
  border: 1px solid var(--brand-1);
  padding: 4px 16px;
  border-radius: 20px;
  box-sizing: border-box;
}
.EducationChasi .chasi_list_header .edu_type {
  position: relative;
  margin-left: 8px;
  font-size: 16px;
  background-color: #f3fbff;
  color: var(--brand-1);
  border: 1px solid #e1f0f8;
  padding: 8px 16px;
  border-radius: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.EducationChasi .chasi_list_header .edu_type_icon {
  display: inline-block;
  box-sizing: border-box;
  margin-right: 4px;
}

.EducationChasi .chasi_list_header .title {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-left: 10px;
  line-height: 1.2;
}

.EducationChasi .chasi_list_header .date_time {
  font-size: 16px;
  color: #707070;
  margin-left: 16px;
}
/* ! 나중에 아래  btn_group에 반복되는거 수정해줘야 됨 */
.buttonGroup {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
}

.buttonGroup .status {
  background-color: #dddede;
  border: 1px solid #989797;
  color: #989797;
  padding: 8px 0;
  width: 100px;
  text-align: center;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 16px;
  cursor: pointer;
}
.buttonGroup .attendance {
  background-color: #bfbfbf;
  color: #fff;
  padding: 8px 0;
  width: 100px;
  text-align: center;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 16px;
}
.buttonGroup .attendanceActive {
  background-color: #fff;
  border: 1px solid #024c8c;
  color: #024c8c;
  padding: 8px 0;
  width: 100px;
  text-align: center;
  border-radius: 6px;
  box-sizing: border-box;
  font-size: 16px;
  cursor: pointer;
}
.buttonGroup .status.completed {
  background-color: var(--brand-5);
  border: 1px solid var(--brand-4);
  color: var(--brand-3);
  cursor: default;
}
.buttonGroup .status.participant_modal {
  background-color: var(--brand-5);
  border: 1px solid var(--brand-4);
  color: var(--brand-3);
  cursor: pointer;
}
.buttonGroup .status.in_progress {
  background-color: #f3fff6;
  border: 1px solid #ceead5;
  color: #659871;
}
.buttonGroup .status.participant_modal.test {
  background-color: #dddede;
  border: 1px solid #bfbfbf;
  color: #989797;
  cursor: default;
}

.buttonGroup .btn_education {
  padding: 8px 20px;
  box-sizing: border-box;
  font-size: 16px;
}

.EducationChasi .chasi_list_body {
  display: flex;
  gap: 4px;
  font-size: 16px;
  color: #505050;
  margin-top: 24px;
  align-items: flex-start;
  flex-direction: column;
  line-height: 1.4;
  overflow-wrap: break-word;
  word-break: keep-all;
}
.EducationChasi .chasi_list_body.stepFin_chasi {
  display: flex;
  gap: 24px;
  align-items: center;
  flex-direction: row;
}

.EducationChasi .chasi_modal_list_body .off_schedule_box {
  margin-top: 12px;
  display: flex;
  gap: 10px;
  font-size: 16px;
}

.off_schedule_box .off_date {
  font-weight: 500;
}

/*.off_schedule_box .off_date::after {*/
/*  display: block;*/
/*  modalContent: "";*/
/*  background-color: var(--gray-6);*/
/*  width: 1px;*/
/*  height: 10px;*/
/*  position: absolute;*/
/*  right: -12px;*/
/*  top: 3px;*/
/*}*/

.EducationChasi .chasi_modal_list_body .chasi_content_detail {
  margin-top: 24px;
  font-size: 16px;
  white-space: pre-line;
  line-height: 1.5;
}

.chasi_content_no_detail {
  margin: 0;
  white-space: pre-line;
  line-height: 1.5;
}

.EducationChasi .chasi_list_body .chasi_content {
  /* margin-right: 24px; */
  position: relative;
  line-height: 1.4;
  overflow-wrap: break-word;
  word-break: keep-all;
  /* text-align: justify; */
}

.EducationChasi .chasi_list_body .title_assign_box {
  display: flex;
  gap: 24px;
  line-height: 1.5;
}

.EducationChasi .chasi_list_body .title_assign_box .chasi_title {
  white-space: pre-line;
}
.EducationChasi .chasi_list_body .title_assign_box .chasi_assign span {
  margin-left: 10px;
  color: #024c8c;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
}
.chasi_offline_box {
  font-size: 16px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--gray-3);
}
.chasi_offline_period_box_container {
  display: flex;
  align-items: center;
  gap: 8px;
}
.chasi_offline_box_period {
  font-weight: 500;
}

.EducationChasi .chasi_list_body.no-assignments .chasi_content::before {
  display: none;
}
.EducationChasi .chasi_list_body.no-assignments .chasi_content::before {
  display: none;
}
.EducationChasi .chasi_list_body .assignment_box {
  display: flex;
  align-items: center;
}
.EducationChasi .chasi_list_body .assignment_box .title {
  left: 0;
}
.EducationChasi .chasi_list_body .assignment_box .assignment {
  left: 30px;
  display: block;
  margin-left: 10px;
  color: var(--brand-1);
  font-weight: 600;
  max-width: 200px;
  line-height: 1.5;
}
/* EducationSchedule에서 사용 */
.EducationChasi .chasi_list_body .assignment_box a.assignment {
  display: block;
  margin-left: 10px;
  text-decoration: underline;
  color: var(--brand-1);
  max-width: 200px;
  line-height: 1.5;
  font-size: 16px;
}

.EducationChasi .chasi_list_body .assignment_box p.assignment {
  margin-left: 10px;
  max-width: 200px;
  line-height: 1.5;
}

/* EducationSchedule에서 사용 */
.EducationChasi .chasi_list_body .trans {
  transform: translate(0, -12px);
}

/* for EducationCourseDetail_chasi  -------------- */
.EducationChasi .education_list_wrapper_forECD {
  border: 1px solid #e8f0f3;
  border-radius: 8px;
}

.EducationChasi .chasi_list_header .chasi_list_title_forECD {
  display: flex;
  align-items: center;
  /* gap: 16px; */
  /* border: 1px solid green; */
  margin-left: 34px;
}

.EducationChasi .chasi_list_header .chasi_forECD {
  position: absolute;
  left: 2.7%;
  font-size: 16px;
  color: var(--brand-1);
  border: 1px solid var(--brand-1);
  padding: 5px 16px;
  border-radius: 20px;
  box-sizing: border-box;
}

.EducationChasi .chasi_list_header .title_forECD {
  font-size: 20px;
  font-weight: 500;
  color: #333;
}

/* 과제가 다운로드 안될 때 컬러 변경함  */
.EducationChasi .chasi_list_body .assignment_box .assignment_disabled {
  left: 30px;
  display: block;
  margin-left: 10px;
  color: #a4a4a4;
  font-weight: 600;
  max-width: 200px;
  line-height: 1.5;
}
.disabledDownload {
  pointer-events: none;
}

/* stepFin_chasi_content */
.stepFin_chasi_content {
  position: relative;
  line-height: 1.5;
  max-width: 80%;
  overflow-wrap: break-word;
  word-break: keep-all;
  text-align: justify;
  white-space: pre-line;
}

@media screen and (max-width: 1280px) {
  .EducationChasi .chasi_item {
    /* background-color: skyblue; */
    padding: 18px 20px;
  }

  .EducationChasi .chasi_list_header {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  .EducationChasi .chasi_list_header .chasi_list_title {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  .EducationChasi .chasi_list_header .title {
    margin-left: 0;
    font-size: 18px;
  }

  .EducationChasi .chasi_list_header .chasi_list_title .chasi {
    font-size: 14px;
  }

  .EducationChasi .chasi_list_header span {
    font-size: 14px;
  }

  .EducationChasi .chasi_list_header .edu_type {
    padding: 4px 12px;
  }

  .EducationChasi .chasi_modal_list_body .chasi_content_detail {
    margin-top: 12px;
    line-height: 1.2;
  }

  .buttonGroup {
    width: 100%;
    margin-top: 12px;
  }

  .buttonGroup > button {
    width: 100%;
  }

  .EducationChasi .chasi_list_body {
    margin-top: 12px;
  }

  .chasi_modal_list_body .off_schedule_box {
    flex-direction: column;
  }

  .off_schedule_box .off_date {
    margin-left: 8px;
  }

  .buttonGroup .status.completed {
    width: 100%;
  }

  .buttonGroup .status.participant_modal {
    width: 100%;
  }

  .EducationChasi .chasi_list_body .title_assign_box {
    display: block;
  }
  .EducationChasi .chasi_list_body .title_assign_box .chasi_assign {
    margin-top: 4px;
  }
  .chasi_offline_box {
    margin-top: 14px;
    align-items: start;
    flex-direction: column;
  }
  .EducationChasi .chasi_offline_box p {
    display: block;
  }
}
