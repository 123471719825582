* {
  box-sizing: border-box;
  word-break: keep-all;
  font-family: "Pretendard", sans-serif !important;
}
/* html */
html {
  /*font-size: 18px;*/
}

/* body */
body {
  line-height: 1;
  font-weight: 400;
  color: #333;
}

#root {
  display: flex;
  flex-flow: column;
}
#content {
  position: relative;
  z-index: 1;
  min-height: 100vh;
  /* min-height: 600px; */
  overflow: clip;
}

/* 모든 텍스트를 포함하는 요소에 단어 단위 줄바꿈 적용 */
p,
div,
span,
a {
  word-wrap: break-word;
  overflow-wrap: break-word; /* 최신 브라우저 대응 */
}

/* color palette */
:root {
  --brand-1: #024c8c;
  --brand-2: #003b6d;
  --brand-3: #3972a3;
  --brand-4: #e1f0f8;
  --brand-5: #f3fbff;
  --brand-6: #bdd5ea;

  --brand-text-black: #1b1b1b;

  --gray-1: #2e2e2e;
  --gray-2: #333333;
  --gray-3: #505050;
  --gray-4: #707070;
  --gray-5: #a4a4a4;
  --gray-6: #bfbfbf;
  --gray-7: #d9d9d9;
  --gray-8: #eeeeee;

  --blue-gray-1: #aaafb9;
  --blue-gray-2: #f8f9fa;

  --table-line-1: #d6e3ea;
  --table-line-2: #e8f0f3;
  --table-line-3: #ebeff2;

  --red-1: #f22727;
  --red-2: #ffe3e3;
  --red-3: #ffb4b1;

  --red-4: #f54949;
  --red-5: #ffe9e8;

  --emerald: #00997d;
}
/* bmc color palette */
.bmc_color_1 {
  background-color: #008df4;
}

.bmc_color_2 {
  background-color: #284255;
}

.bmc_color_3 {
  background-color: #6dd295;
}

.bmc_color_4 {
  background-color: #8800f4;
}

.bmc_color_5 {
  background-color: #9e0000;
}

.bmc_color_6 {
  background-color: #f400db;
}

.bmc_color_7 {
  background-color: #ff4a4a;
}

.bmc_color_8 {
  background-color: #ff9900;
}

/* 접근성 hidden */
.hidden {
  display: block;
  position: absolute;
  left: -1000%;
  width: 1px;
  height: 1px;
  overflow: hidden;
  color: #333;
}

.cursor {
  cursor: pointer;
}

/* react - confrim - alert*/
.popup-overlay {
  background-color: rgba(0, 0, 0, 0.7);
  transition: none;
}
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.7);
  transition: none;
  animation: react-confirm-alert-fadeIn 0.2s 0s forwards !important;
}

/* 버튼 */
.MyButton {
  min-width: 100px;
  width: auto;
  padding: 12px;
  opacity: 1;
  transition: all 0.2s ease-in-out;
  border-radius: 6px;
  font-family: "Pretendard", sans-serif;
  font-size: 16px;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  line-height: 1.5;
}
.MyButton img {
  vertical-align: middle;
}
.MyButton:hover {
  opacity: 1;
}

.MyButton.MyButton_positive {
  background-color: var(--brand-1) !important;
  color: #fff !important;
}
.MyButton.MyButton_disabled {
  background-color: #eeeeee !important;
  color: var(--gray-5) !important;
}
.MyButton.MyButton_normal {
  background-color: var(--brand-4) !important;
  color: var(--brand-1) !important;
}
.MyButton.MyButton_normalLine {
  background-color: var(--brand-5) !important;
  border: 1px solid var(--brand-4) !important;
  color: var(--brand-1) !important;
  font-weight: 500;
}
.MyButton.MyButton_negative {
  font-weight: 300;
  background-color: var(--red-2) !important;
  color: var(--red-1) !important;
  border: 1px solid var(--red-3) !important;
}
.MyButton.MyButton_modify {
  font-weight: 300;
  background-color: var(--blue-gray-2) !important;
  color: var(--brand-1) !important;
  border: 1px solid var(--brand-1) !important;
}
.MyButton.MyButton_grayLine {
  font-weight: 500;
  background-color: white !important;
  color: var(--gray-4) !important;
  border: 1px solid var(--gray-5) !important;
}

/* 에러 메세지 */
.validate.warning {
  color: #f44336;
  font-size: 14px;
}

/* input type number 화살표 없애기 */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type="number"] {
  -moz-appearance: textfield;
}

/* ... 표시 */
.text_truncate {
  /* data 길어질 때 처리  같은 태그에 width 지정 해야 함  */
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: block !important;
  /*font-size: 18px;*/
}

.white-space-nowrap {
  white-space: nowrap;
}

.flex {
  display: flex;
}
.items-center {
  display: flex;
  align-items: center;
}
.justify-between {
  display: flex;
  justify-content: space-between;
}
.justify-center {
  display: flex;
  justify-content: center;
}
.justify-end {
  display: flex;
  justify-content: end;
}
.items-justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.text-center {
  text-align: center;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}

.font-weight-500 {
  font-weight: 500;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-300 {
  font-weight: 300;
}
.font-weight-200 {
  font-weight: 200;
}
.font-weight-100 {
  font-weight: 100;
}

.lazy_image {
  transition: opacity 0.5s ease-in;
}
.fade-out {
  opacity: 0.4;
}

.fade-in {
  opacity: 1;
}

/* toast ui 자간 수정 */
.Toastify__toast-body > div:last-child {
  line-height: 1.5;
}
.myTestBox {
  position: fixed;
  top: 10%;
  left: 10%;
  padding: 30px;
  color: white;
  background-color: black;
  font-size: 20px;
  font-weight: 700;
}

/* 커스텀 스크롤 */
/* 세로 */
.customScroll-vertical::-webkit-scrollbar {
  width: 10px;
}
/* size theme */
.customScroll-vertical {
  &.medium::-webkit-scrollbar {
    width: 6px;
  }
  &.thin::-webkit-scrollbar {
    width: 4px;
  }
}
.customScroll-vertical::-webkit-scrollbar-thumb {
  background-color: #cbc7c7;
  border-radius: 5px;
}
.customScroll-vertical::-webkit-scrollbar-track {
  background-color: #efefef;
}

/* 가로 */
.customScroll-horizontal::-webkit-scrollbar {
  height: 6px;
}
/* size theme */
.customScroll-horizontal {
  &.medium::-webkit-scrollbar {
    height: 4px;
  }
  &.thin::-webkit-scrollbar {
    height: 2px;
  }
}
.customScroll-horizontal::-webkit-scrollbar-thumb {
  background-color: #cbc7c7;
  border-radius: 5px;
}
.customScroll-horizontal::-webkit-scrollbar-track {
  background-color: #efefef;
}

.navigate_transition_overlay {
  width: 100%;
}

.navigate_transition_overlay.isHide {
  display: none !important;
}

.requireDesc {
  color: var(--red-1);
  font-size: 16px;
  font-weight: 300;
}

.transition-fade {
  opacity: 1;
  transition: 0.5s;
  transform: translateX(0);
  transform-origin: left;
}

html.is-animatibg .transition-fade {
  opacity: 1;
  transform: translateX(100%);
}

html.is-leaving .transition-fade {
  opacity: 0;
  transform: translateX(0);
}

.loading-cover {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  z-index: 999999;
  left: 0;
  top: 0;
}

.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 999999;
  color: var(--brand-2);
  font-weight: 600;
}

.btn {
  padding: 4px 16px;
  border-radius: 30px;
  box-sizing: border-box;
  cursor: pointer;
  /* font */
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
/* btn option ------- */
/* 버튼 옵션 */
.btn.cusorAuto {
  cursor: auto;
}
.btn.marginLeft8 {
  margin-left: 8px;
}
.btn.minWidth100 {
  min-width: 120px;
}
/* ManagementContent  ------ */
/* 수강생 관리 */
.btn.sty_1 {
  border: 1px solid #024c8c;
  background-color: #fff;
  color: #024c8c;
}
/* 관리버튼인 경우 */
.btn.sty_1.management {
  border: 1px solid #024c8c;
  padding: 4px 14px 4px 12px;
  background-color: #fff;
  color: #024c8c;
  width: 78px !important;

  &.isHover {
    background-color: var(--brand-4);
  }
}
.btn.sty_2 {
  border: 1px solid #024c8c;
  background-color: #024c8c;
  color: #fff;
}

/* 수업상태 */
.btn.statusSty_1 {
  background-color: #e1f0f8;
  color: #3972a3;
  border: 1px solid #e1f0f8;
  padding: 4px 22px;
}
.btn.statusSty_2 {
  background-color: #bfbfbf;
  color: #fff;
  border: 1px solid #bfbfbf;
  /* padding: 4px 22px; */
}

.btn.statusSty_3 {
  background-color: #bfbfbf;
  color: #fff;
  border: 1px solid #bfbfbf;
  padding: 4px 22px;
}
/*  */
.sty_metoringApply1 {
  background-color: #bfbfbf;
  color: #a4a4a4;
  border-radius: 6px;
  border: none;
  margin-left: 0px;
}
.sty_metoringApply2 {
  background-color: #024c8c;
  color: #fff;
  border-radius: 6px;
  border: none;
}

.common_download_button {
  transition: all ease-in-out 0.1s;
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1.5;
  font-size: 16px;
  padding: 8px 16px 8px 10px;
  border-radius: 4px;
  color: var(--brand-1);
  font-weight: 500;
  background-color: var(--brand-4);

  &:hover {
    background-color: #d7eafb;
  }
}

/* 모달 스크롤 스타일 */
.vertical_custom_scroll {
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cbc7c7;
    border-radius: 5px;
  }
}

.horizontal_custom_scroll {
  overflow-y: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar:horizontal {
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cbc7c7;
    border-radius: 5px;
  }
}

.line_clamp1 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.line_clamp2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

/* 프로그램개설 부 틀팁 스타일 */
.tooltip_icon_v1 {
  font-size: 14px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: var(--blue-gray-1);
  color: white;
  cursor: default;
  margin-right: 4px;
}

@media screen and (max-width: 1280px) {
  /* 관리버튼 */
  .btn.sty_1.management {
    display: flex;
    justify-content: center;
    width: 78px !important;

    & > .management_button_wrapper {
      transform: translateX(-2px);
    }
  }
}
