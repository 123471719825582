div.paginationContainer {
  /* border: 1px solid green; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a4a4a4;
  font-size: 16px;
  margin-top: 30px;
}
ul.pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
}

ul.pagination li {
  display: inline-block;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  /*  */
  margin-right: 24px;
  padding: 12px;
}

ul.pagination li:first-child {
  border-radius: 5px 0 0 5px;
  margin-right: 0;
}

ul.pagination li:nth-last-child(2) {
  margin-right: 0;
}

ul.pagination li:last-child {
  border-radius: 0 5px 5px 0;
  margin-right: 0px;
}

ul.pagination li a {
  text-decoration: none;
  color: #a4a4a4;
  font-size: 1rem;
}

ul.pagination li.active a {
  color: #333333;
}

.page-selection {
  width: 48px;
  height: 30px;
  color: #337ab7;
}

@media screen and (max-width: 640px) {
  ul.pagination li {
    margin-right: 12px;
  }
}
