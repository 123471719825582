.table {
  width: 1320px;
}
.table .thead {
  /* background-color: skyblue; */
  font-weight: 400;
  font-size: 16px;
  line-height: 52px;
  color: #707070;
  border-bottom: 1px solid #c8d4db;
  display: flex;
  /* justify-modalContent: space-between; */
}

.table .tbody {
  font-weight: 300;
  font-size: 18px;
  /* line-height: 72px; */
  color: #333333;
}
.table .tbody .trow {
  border-bottom: 1px solid #c8d4db;
  /* background-color: orange; */
  display: flex;
  align-items: center;
}
.table .tbody .trow:hover {
  background-color: #f3fbff;
}

/* 하단에 columns에 지정해준 className으로 별도의 css 작성 가능 */
/* ManagementContent 컴포넌트 적용 */
.projectCol {
  flex-grow: 1;
  max-width: 584px;
}
.table .tbody .projectCol {
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #024c8c;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px 0;
  overflow: hidden;
}
.statusCol {
  width: 112px;
  min-width: 112px;
  display: flex;
  justify-content: center;
}
.eduFieldCol {
  display: flex;
  justify-content: center;
  width: 180px;
  min-width: 180px;
}
.eduDateCol {
  min-width: 210px;
  width: 210px;
}
.managementCol {
  width: 234px;
  display: flex;
  gap: 2px;
  padding: 20px 0 20px 10px;
}
.table_pagination {
  margin-top: 54px;
}
.edu_group_project_icon_container {
  display: flex;
  gap: 4px;
  margin-right: 12px;
}
.edu_group_project_icon_wrapper,
.edu_group_project_leader_icon_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 28px;
  height: 28px;
  border-radius: 6px;
  padding: 2px;
}
.edu_group_project_icon_wrapper {
  background-color: var(--brand-4);
}
.edu_group_project_leader_icon_wrapper {
  background-color: #FFEBAA;
}
.edu_group_project_leader_icon {
  fill: #F6A800;
}

.education_management_list_project_name_wrapper {
  max-width: 540px;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
}

@media screen and (max-width: 1280px) {
  /* 컨텐츠 */
  .table {
    width: 100%;
  }

  .table .thead {
    display: none;
  }

  .table .tbody .trow {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .table .tbody .trow:first-child {
    border-top: 1px solid #dae6eb;
  }

  .table .tbody .trow li {
    width: 100% !important;
    text-align: left !important;
    padding: 10px 0;
  }

  .table .tbody .trow li:nth-child(3) {
    font-weight: 600;
  }

  .table .tbody .trow li:nth-child(4) {
    color: var(--gray-4);
    font-size: 14px;
  }

  .table .tbody .trow li:last-child {
    gap: 6px;
    font-size: 14px;
  }

  .table .tbody .trow li:last-child button {
    font-size: 14px;
    width: calc((100% - 3 * 6px) / 4);
    padding: 2px 3px;
    border-radius: 4px;
  }
}
