.notExistBoardCommentWrap {
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 100%;
    height: 100%;
    min-height: 500px;
    cursor: default;
}
.notExistBoardComment {
    font-size: 20px;
    font-weight: 400;
    color: var(--gray-4);
}
.notExistMainCommentWrap {
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none;

    cursor: default;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%;
    height: 100%;
    min-height: 500px;
}
.commentWrap {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    color: var(--gray-4);
}
.mainComment {
    font-size: 36px;
    font-weight: 500;
}
.subComment {
    font-size: 16px;
    font-weight: 400;
}