.home_third_wrapper {
  background: url("@/assets/images/home/consortium_background.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
  background-color: var(--brand-5);
  position: relative;
}

.home_third_contents_wrapper {
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
}

.home_third_map_wrapper {
  width: 600px;
  min-width: 600px;
  height: 745px;
  content: "";
  /* background-image: url("@/assets/images/university/consortium_map.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  align-items: center;
  margin: auto 0;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
}

.home_third_map_wrapper.fade-out {
  opacity: 0.5;
  /* 새 이미지가 처음에 흐린 상태 */
}

.home_third_map_wrapper.fade-in {
  opacity: 1;
  /* 점점 밝아짐 */
}

.home_third_map_img {
  z-index: 10;
}

.home_third_map_button > button {
  border: 0;
  background-color: transparent;
  background-color: var(--gray-4);
  border: 0;
  border-radius: 17px;
  color: #fff;
  padding: 4px 12px;
  line-height: 24px;
  font-size: 16px;
  cursor: pointer;
}

.home_third_map_button .home_third_map_icon {
  position: relative;
  left: 18px;
  bottom: 10px;
}

.home_third_map_button > button.active {
  background-color: var(--brand-1);
  transition: all 0.8s;
}

.home_third_map_button > button:hover {
  background-color: var(--brand-1);
  transition: all 0.8s;
}

.home_third_map_buttons .capital {
  position: absolute;
  top: 160px;
  left: 125px;
}

.home_third_map_buttons .chungcheong {
  position: absolute;
  top: 260px;
  left: 165px;
}

.home_third_map_buttons .honam_jeju {
  position: absolute;
  top: 410px;
  left: 110px;
}

.home_third_map_buttons .dongnam {
  position: absolute;
  top: 425px;
  left: 320px;
}

.home_third_map_buttons .kangwon {
  position: absolute;
  top: 140px;
  left: 330px;
}

.home_third_university_info_wrapper {
  /*background: url("@/assets/images/home/pencil_image.png") no-repeat,*/
  /*  url("@/assets/images/home/CONSORTIUM.png") no-repeat;*/
  /* padding-top: 100px; */
  /* margin: auto 0;/ */
  background-repeat: no-repeat;
  background-size: 50px, 85%;
  background-position: 160px 80px, 0px 55px;
  width: 100%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home_third_university_info {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.home_third_university_consortium {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.home_third_university_consortium_title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.home_third_university_consortium_list {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  gap: 16px;
  width: 100%;
  animation: slideUp 0.5s ease-in-out;
}

.home_third_university_consortium_list_item {
  background-color: white;
  border-radius: 12px;
  padding: 22px 24px;
  font-weight: 550;
  font-size: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
  height: 80px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  border: 1px solid #DAE6EB;
  box-shadow: 16px 16px 40px 0 rgba(56, 86, 113, 0.10);
}

.home_third_university_consortium_list_item:hover {
  transition: all 0.5s;
  opacity: 1;
  transform: scale(1.05);
}

.home_third_university_consortium_list_item > img {
  width: 48px;
  margin-right: 16px;
  flex-shrink: 0;
}

.home_third_university_search_wrapper {
  background: url("@/assets/images/home/search_bar_image.png") no-repeat;
  background-repeat: no-repeat;
  background-position: 290px 97px;
  padding-top: 200px;
}

.home_third_university_search_title {
  color: var(--brand-1);
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}

.home_third_university_search_input {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.home_third_university_search_input_form {
  width: 100%;
  height: 45px;
  margin: 12px 0;
  border: 3px solid var(--brand-1);
  border-radius: 8px;
  font-size: 16px;
  padding: 8px 15px;
  counter-reset: var(--gray-5);
}

.home_third_search_icon {
  display: flex;
  align-items: center;
}

.home_third_university_search_list {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.home_third_university_search_list_item {
  background-color: var(--brand-1);
  border-radius: 8px;
  padding: 12px 10px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

@media screen and (max-width: 1280px) {
  .home_third_wrapper {
    background-image: none;
    height: auto;
    padding-top: 56px;
    position: relative;
  }

  .home_third_wrapper::before {
    content: '';
    background-image: url("@/assets/images/home/third/main_third_half_circle.png");
    position: absolute;
    display: block;
    width: 90px;
    height: 186px;
    top: 34px;
    left: 0;
  }

  .home_third_wrapper::after {
    content: '';
    width: 106px;
    height: 67px;
    position: absolute;
    bottom: 24px;
    right: 24px;
    background-image: url("@/assets/images/home/third/main_consortiu_cloud.png");
  }




  .home_third_map_wrapper::after {
    content: '';
    width: 57px;
    height: 55px;
    position: absolute;
    bottom: 15px;
    right: 28px;
    background-image: url("@/assets/images/home/third/effect_circle_dash.png");
    background-size: cover;
  }

  .home_third_contents_wrapper {
    flex-direction: column;
    align-items: center;
    row-gap: 42px;
  }



  /* 권역 */
  .home_third_university_info_wrapper {
    width: auto;
    max-width: none;
  }

  .home_third_university_info_wrapper .globalTabContainer {
    margin-top: 0;
    justify-content: center;
    border-bottom: 1px solid #E8F0F3;
    position: relative;
  }

  .home_third_university_info_wrapper .globalTabContainer::after {
    content: 'CONSORTIUM';
    display: block;
    position: absolute;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 48px;
    font-weight: 800;
    /* z-index: -1; */
  }

  .home_third_university_info {
    padding-top: 24px;
    padding-bottom: 40px;
  }

  .home_third_university_consortium_list {
    grid-template-columns: repeat(2, max-content);
  }



}


@media screen and (max-width: 640px) {
  .home_third_map_wrapper {
    /* margin-top: 56px; */
    width: 300px;
    min-width: 300px;
    height: 412px;
    position: relative;
  }


  /* 마커 위치 */
  .home_third_map_buttons .capital {
    top: 90px;
    left: 17px;
  }

  .home_third_map_buttons .kangwon {
    top: 90px;
    left: 157px;
  }

  .home_third_map_buttons .chungcheong {
    top: 160px;
    left: 55px;
  }

  .home_third_map_buttons .honam_jeju {
    top: 220px;
    left: -14px;
  }

  .home_third_map_buttons .dongnam {
    top: 237px;
    left: 151px;
  }

  .home_third_university_info {
    padding-bottom: 0;
  }

  .home_third_university_consortium_list.Even {
    padding-bottom: 140px;
  }

  .home_third_university_consortium_list.Odd {
    padding-bottom: 40px;
  }

  .home_third_university_consortium_list_item > img {
    width: 32px;
  }

  .home_third_university_consortium_list_item {
    width: 152px;
    height: 88px;
    gap: 6px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 12px 20px;
  }
}