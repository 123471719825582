.home_second_wrapper {
  background: url("@/assets/images/home/home_2_wallpapaer.png") no-repeat;
  /*background-color: skyblue;*/
  background-size: cover;
  width: 100%;
  /*height: 100vh;*/
  height: calc(100vh - 60px);
  /*min-height: 1200px;*/
  display: flex;
  align-items: center;
  /*border: 1px solid red;*/
  background-color: #1A90CC;
  position: relative;
}

.home_second_contents_wrapper {
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  /*padding: 100px 0 100px 0;*/
  position: relative;
  /*border: 1px solid red;*/
}

.home_second_programs_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}

.home_second_programs_title,
.home_second_education_contents_title {
  display: flex;
  justify-content: flex-start;
  color: white;
  font-size: 32px;
  font-weight: 600;
}

.home_second_programs_pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  /* 버튼과 텍스트 사이 간격 */
  font-size: 16px;
  color: white;
}

.home_second_programs_pagination_button {
  padding: 4px 8px;
  /* 버튼 크기 조정 */
  background: none;
  border: none;
  cursor: pointer;
}

.home_second_programs_pagination_button:disabled {
  color: #ccc;
  /* 비활성화 상태일 때 색상 */
  cursor: not-allowed;
}

.home_second_programs_pagination_text {
  display: flex;
  align-items: center;
  gap: 12px;
  /* 숫자와 구분자 사이 간격 */
}

.home_second_programs_cards {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: visible !important;
  width: 100%;
  transition: transform 0.5s ease-in-out;
  /* 애니메이션 추가 */
}

.home_second_programs_cards .swiper-wrapper {
  display: flex;
  padding-top: 20px;
}

.home_second_programs_cards .swiper-slide {
  width: 100%;
  margin-right: 10px;
  box-sizing: border-box;
}

.home_second_program_card {
  height: auto;
  margin: 0;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
}

.home_second_program_card:hover {
  transform: translateY(-20px);
  /* 살짝 위로 이동 */
  z-index: 10;
}

.home_second_program_card.active {
  transform: scale(1.1);
  /* 활성화된 카드 강조 */
  opacity: 1;
  /* 활성화된 카드 완전히 보이도록 */
}

.slide-next {
  transform: translateX(-50%);
}

.slide-prev {
  transform: translateX(50%);
}

.home_second_program_card .card_box {
  width: 100%;
  background: white;
  box-shadow: none;
  padding: 30px;
  /* height: 100%; */
}

.home_second_program_card .card_sub_title {
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  padding: 0px;
}

.home_second_program_card .thumbnail_box {
  height: 110px;
  width: 100%;
}

.home_second_program_card .nav_btn {
  font-size: 16px;
  font-weight: 500;
  display: inline-flex;
  color: var(--brand-1);
  gap: 15px;
  display: flex;
  align-items: center;
}

.home_second_program_card .nav_btn img {
  width: 0.7em;
  /* 폰트 크기에 상대적인 너비 */
  height: 0.7em;
  /* 폰트 크기에 상대적인 높이 */
  object-fit: contain;
  /* 이미지 비율 유지 */
}

.home_second_program_card .card_title {
  font-size: 24px;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  line-height: 1.5;
}

.home_second_program_card .card_box .regionTrans {
  margin: 20px 0 20px;
}

.home_second_program_card .card_box .regionTrans ul {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 6px;
}

.home_second_pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: white;
  margin-top: 20px;
}

.home_second_pagination button {
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.home_second_education_contents {
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: row;
  padding-top: 100px;
}

.home_second_education_contents_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.home_second_education_contents_left {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.home_second_education_contents_description {
  color: white;
  font-size: 16px;
  text-align: left;
  margin-top: 40px;
  line-height: 1.5;
}

.home_second_education_contents_right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  width: 100%;

  margin-left: 120px;
}

.home_second_education_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.home_second_education_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: white;
  padding: 15px 0;
  border-top: 1px solid #fff;
  cursor: pointer;
  line-height: 1.5;
}

.home_second_education_list_li_kind {
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.home_second_education_item:last-child {
  border-bottom: 1px solid #fff;
}

.home_second_education_kind {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 14px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  line-height: 150%;
  border-radius: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.home_second_education_kind.region {
  background-color: white;
  color: var(--brand-1);
}



.home_second_education_list_li_title {
  /* margin-left: 10px; */
  font-weight: 500;
}

.home_second_wrapper .eduModal_container {
  text-align: left;
}



@media screen and (max-width: 1280px) {
  .home_second_wrapper {
    height: auto;
    background-image: none;
  }

  .home_second_wrapper::after {
    content: '';
    display: block;
    position: absolute;
    width: 71px;
    height: 130px;
    right: 0;
    top: 25px;
    background-image: url('@/assets/images/home/main_second_half_circle.png');
  }

  .home_second_program_card:hover {
    transform: none;
  }

  .home_second_contents_wrapper {
    padding: 0 24px;
    padding-bottom: 52px;
  }

  .home_second_education_contents {
    padding-top: 70px;
  }

  .home_second_education_contents_wrapper {
    flex-direction: column;
    row-gap: 20px;
  }

  .home_second_education_contents_description {
    margin-top: 4px;
    font-size: 13px;
  }

  .home_second_education_contents_left {
    width: 100%;
  }

  .home_second_education_contents_right {
    margin: 0;
  }
}

@media screen and (max-width: 640px) {

  .home_second_programs_title,
  .home_second_education_contents_title {
    font-size: 16px;
  }

  .home_second_program_card .card_box .regionTrans {
    gap: 2px;
    margin-bottom: 12px;
  }

  .home_second_education_item {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
    padding: 20px 0;
  }

  .home_second_education_info {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
  }

  .home_second_education_list_li_kind {
    gap: 20px;
  }

  .home_second_education_kind,
  .home_second_education_kind.region {
    padding: 0;
    background: none;
    color: white;
    position: relative;
  }

  .home_second_education_kind::after {
    content: '';
    display: block;
    height: 12px;
    width: 1px;
    background-color: white;
    position: absolute;
    right: -10px;
  }

  .home_second_education_kind:last-child:after {
    content: none;
  }

  .home_second_education_list_li_created_at {
    font-size: 14px;
  }
}