@font-face {
  font-family: "Onest";
  font-weight: 100;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/Onest/Onest-Thin.woff") format("woff"),
    url("../assets/fonts/Onest/Onest-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Onest";
  font-weight: 200;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/Onest/Onest-ExtraLight.woff") format("woff"),
    url("../assets/fonts/Onest/Onest-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Onest";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/Onest/Onest-Light.woff") format("woff"),
    url("../assets/fonts/Onest/Onest-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Onest";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/Onest/Onest-Regular.woff") format("woff"),
    url("../assets/fonts/Onest/Onest-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Onest";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/Onest/Onest-Medium.woff") format("woff"),
    url("../assets/fonts/Onest/Onest-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Onest";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/Onest/Onest-SemiBold.woff") format("woff"),
    url("../assets/fonts/Onest/Onest-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Onest";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/Onest/Onest-Bold.woff") format("woff"),
    url("../assets/fonts/Onest/Onest-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Onest";
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/Onest/Onest-ExtraBold.woff") format("woff"),
    url("../assets/fonts/Onest/Onest-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Onest";
  font-weight: 800;
  font-style: normal;
  font-display: swap;
  src: url("../assets/fonts/Onest/Onest-Black.woff") format("woff"),
    url("../assets/fonts/Onest/Onest-Black.ttf") format("truetype");
}
