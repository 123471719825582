.inspection_area {
  padding: 28px 30px;
  text-align: center;
}

.inspection_area .inspection_title {
  font-size: 24px;
  line-height: 1.5;
  font-weight: 600;
  color: #024c8c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 34px;
  gap: 10px;
}

.inspection_area .inspection_content {
  text-align: center;
  padding: 32px 54px;
  background-color: #eaf5ff;
  border-radius: 24px;
}

.inspection_area .inspection_content .inspection_description {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  padding-bottom: 24px;
  margin-bottom: 12px;
  width: 100%;
  border-bottom: 1px solid #bfdeee;
}

.inspection_area .inspection_content .inspection_time_area {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.inspection_area .inspection_content .inspection_time_title {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #024c8c;
  padding: 2px 10px;
  border-radius: 30px;
}
.inspection_area .inspection_content .inspection_time {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #024c8c;
}

.inspection_area .inspection_content .mt-6 {
  margin-top: 24px;
}

@media screen and (max-width: 1024px) {
  .inspection_area {
    padding: 0;
  }

  .inspection_area .inspection_content {
    padding: 12px;
  }
}
