.main_tab_wrapper {
    max-width: 1100px;
    margin: 0 auto;
    padding-bottom: 50px;
}



.main_tab_wrapper .tab_sub_title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
    color: var(--brand-1);
}

.main_tab_wrapper .tab_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid var(--table-line-1);
}

.tab_box .tab_btn_box {
    display: flex;
    gap: 16px;
}

.main_tab_wrapper .tab_box .tab_btn {
    padding: 10px 24px;
    border-radius: 30px;
    border: 1.5px solid var(--brand-1);
    color: var(--brand-1);
    background-color: #fff;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;
}

.tab_btn_box .tab_btn.active_tab {
    border: 1.5px solid var(--brand-1);
    color:  #fff;
    background-color: var(--brand-1);
    font-weight: 600;
}

.main_tab_wrapper .nav_btn {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 8px;
    color: var(--brand-1);
    font-weight: 500;
}

.main_tab_wrapper .nav_btn img {
    width: 8px;
    height: 12px;
    display: block;
}

.tab_content_wrapper {
    width: 97%;
    margin: 0 auto;
}

/* 바로가기 */
.tab_content_wrapper .nav_btn_box {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px;
}

.tab_content_wrapper .nav_btn_box .nav_btn {
    transition: transform 0.2s ease; /* 부드러운 애니메이션 */
    transform-origin: center; /* 기본값: 중심 */
}

.tab_content_wrapper .nav_btn_box .nav_btn:hover {
    transform: scale(1.1); /* 버튼 크기를 1.1배 확대 */
}

/*.tab_content_wrapper .nav_btn_box:hover {*/
/*    scale: 1.2;*/
/*}*/

@media screen and (max-width: 1280px) {
    .main_tab_wrapper {
        margin-top: 80px;
        width: 90%;
        padding-bottom: 0;
    }

    .main_tab_wrapper .tab_box {
        align-items: center;
    }


    .main_tab_wrapper .tab_box .tab_btn {
        font-size: 16px;
        padding: 3px 10px;
    }

    .main_tab_wrapper .tab_box {
        padding-bottom: 20px;
        /*border-bottom: none;*/
    }

    .mobile_tab_title {
        font-weight: 600;
        color: #fff;
        font-size: 20px;
        display: inline-block;
        padding: 8px 16px;
        border-radius: 20px;
        background-color: var(--brand-1);
    }
}
