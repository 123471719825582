/* confrim modal */
.confirm_modal .btn_confirm_group {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.confirm_modal .confirm_desc {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 400;
  padding: 16px 0 24px;
}

.confirm_modal_cancel_button, .confirm_modal_confirm_button {
  &.MyButton {
    padding: 8px 0;
  }
}