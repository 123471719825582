.GlobalSearchTextInput {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 52px 0 35px;
}

.GlobalSearchTextInput .left_box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.GlobalSearchTextInput .left_box .description {
  color: #024c8c;
  font-weight: 600;
  font-size: 18px;
}

.GlobalSearchTextInput .SearchInput {
  justify-content: end;
}

@media screen and (max-width: 1280px) {
  .GlobalSearchTextInput {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .GlobalSearchTextInput {
    display: block;
    margin: 32px 0 20px;
  }
  .GlobalSearchTextInput .left_box {
    margin-bottom: 12px;
  }
  .GlobalSearchTextInput .left_box .description {
    font-size: 16px;
  }
}
