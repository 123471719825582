/* 기본 셀렉트 없애기 */
select::-ms-expand {
  display: none;
}

/* custom */
.customSelectBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  max-height: 52px;
  border-radius: 6px;
  width: 152px;
  background: var(--blue-gray-2);
  /*padding: 9px 16px 9px 24px;*/
}

.customSelectBox .select {
  width: 100%;
  height: inherit;
  background: transparent;
  border: 0 none;
  outline: 0 none;
  padding: 16px 24px;
  position: relative;
  z-index: 3;
  -o-appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  font-size: 16px;
  color: var(--gray-2);
  font-weight: 300;
  line-height: 1.5;
  cursor: pointer;
}

/* .customSelectBox .select option {
  position: absolute;
} */

.customSelectBox .icon_arrow {
  position: absolute;
  top: calc(50% + 2px);
  right: 16px;
  z-index: 1;
  width: 35px;
  height: inherit;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
/* 
.customSelectBox .select:focus + .icon_arrow img {
  border: 1px solid #024c8c;
  outline: 1px solid #024c8c;
} */
