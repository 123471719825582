/* common detail */

.commonDetailTitle {
  font-size: 48px;
  font-weight: 700;
}
.commonDetailTitle.contents {
  font-size: 32px;
  font-weight: 600;
}
.commonDetailSubInfo {
  width: 100%;
  font-weight: 300;
  color: var(--gray-4);
  display: flex;
  gap: 24px;
  padding: 20px 0 27px;
  border-bottom: solid 1px var(--table-line-1);
}
.commonDetailSubHeaderBold {
  color: var(--gray-2);
  font-weight: 300;
}
.commonDetailContent {
  height: 100%;
  padding: 70px;
  margin: 0 auto;
}

.commonDetailSubTitle {
  color: var(--brand-1);
  margin-bottom: 20px;
  font-weight: 600;
}

.commonDetailSubTitle.contents {
  margin-top: 60px;
}

.commonDetailContent .gallery_img_item {
  width: 60%;
  display: block;
  margin: 0 auto 30px;
}

.commonDetailContent .player_box {
  width: 100%;
  max-width: 1000px;
  height: 600px;
  margin: 0 auto 30px;
}

.commonDetailContent .text_center {
  text-align: center;
}

/* .commonDetailContent .companyDetailContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 30px;
}

.commonDetailContent .companyDetailContainer .companyDetailImage {
  width: 60%;
  display: block;
  margin: 0 auto 30px;
}

.commonDetailContent .companyDetailContainer .companyDetailHeadline {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
}

.commonDetailContent .companyDetailContainer .companyDetailHeadline .title {
  font-size: 22px;
  font-weight: 600;
} */

.commonDetailContent .companyDetailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  /* padding: 0 !important; */
  margin: 0px -70px;
  /* background-color: #f8f9fc; */
  /* border: 1px solid #ddd; */
  border-radius: 10px;
}

.commonDetailContent .companyDetailContent {
  display: flex;
  flex-direction: row;
  gap: 30px;
  align-items: flex-start;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
}

.commonDetailContent .companyDetailImageWrapper {
  flex-shrink: 0;
}

.commonDetailContent .companyDetailImage {
  /* width: 200px; */
  width: 100%;
  height: 200px;
  object-fit: contain;
  border: transparent;
  border-radius: 10px;
}

.commonDetailContent .companyDetailInfo {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;
  font-size: 18px;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.commonDetailContent .infoRow {
  display: flex;
  justify-content: space-between;

  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 100%;
}

.commonDetailContent .title {
  font-weight: bold;
  color: #333;
  min-width: 120px;
}

.commonDetailContent .value {
  color: #555;
  max-width: 300px;
  text-align: right;
  word-wrap: break-word;
  overflow-wrap: break-word;
  flex: 1;
}

.commonDetailFileContainer {
  display: flex;
  /* align-items: center; */
  gap: 30px;
  padding: 22px 0;
  border-top: solid 1px var(--table-line-1);
}
.commonDetailFileHeadline {
  display: flex;
  /* align-items: center; */
  gap: 10px;
}
.commonDetailFileLink {
  display: flex;
  margin-bottom: 20px;
  gap: 20px;
  flex-direction: column;
  /* align-items: center; */
  color: var(--brand-1);
}
.commonDetailFileLink:hover {
  text-decoration: underline;
}
.commonDetailFooter {
  display: flex;
  justify-content: center;
  width: 100%;
}
.commonDetailToListButton {
  font-size: 20px !important;
  margin: 60px 0 !important;
  padding: 11px 85px !important;
}
.commonDetailCenterDot {
  margin: 0 6px;
}

.button-container {
  display: flex;
  gap: 10px;
  position: absolute;
  top: 20px;
  right: 20px;
}

.edit-button,
.delete-button {
  padding: 10px 30px;
  border: none;
  border-radius: 40px;
  font-size: 16px;
  cursor: pointer;
}

.edit-button {
  background-color: var(--brand-4);
  color: var(--brand-3);
}

.delete-button {
  background-color: #ffc5b2;
  color: darkred;
}

.edit-button:hover {
  background-color: var(--brand-3);
  color: white;
}

.delete-button:hover {
  color: white;
  background-color: #d2795d;
}

.mentoringDetailContent {
  max-width: 100%;
}

.QnADetailHeaderTitle {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 40px;
}
.QnADetailContentBox {
  box-shadow: 0 4px 30px 0 #aec1d033;
  border: solid 1px var(--table-line-2);
  border-radius: 32px;
  padding: 28px 46px 36px;
  margin-bottom: 60px;
}
.QnADetailInquiryWrap {
  width: fit-content;
  height: fit-content;
  background-color: var(--brand-5);
  border-radius: 6px;
}
.QnADetailReplyTitle {
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500;
  font-size: 24px;
  color: var(--brand-1);
}
.QnADetailReplyEmptyBox {
  display: flex;
  justify-content: center;
  background-color: var(--blue-gray-2);
  padding: 46px;
  border-radius: 12px;
}
.QnADetailReplyEmptyContent {
  display: grid;
  gap: 16px;
}
.QnADetailFooter {
  display: flex;
  justify-content: center;
}
.QnADetailHeaderSection {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px var(--table-line-2);
  padding-bottom: 10px;
  margin-bottom: 40px;
}
.QnADetailContentTitle {
  font-size: 24px;
  font-weight: 500;
  word-break: break-all;
}
.QnADetailContentTitleArea {
  align-items: center;
  display: flex;
}
.QnADetailContentTitleInfoArea {
  display: flex;
  gap: 32px;
}
.QnADetailContentCreator {
  margin-right: 18px;
  font-weight: 500;
}
.QnADetailContentCreateAt {
  display: flex;
  gap: 10px;
  font-weight: 300;
}
.QnADetailContentButtons {
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 7px;
}
.QnADetailContentButtonsDivider {
  color: var(--table-line-2);
}
.QnADetailContentButton {
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: flex;
  gap: 4px;
}
.QnADetailCreateReplyButtons {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 14px;
}
.QnADetailCreateReplyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 135px;
  height: 52px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;

  &.cancel {
    color: var(--brand-1);
    background-color: var(--brand-4);
  }
  &.registration {
    color: white;
    background-color: var(--brand-1);
  }
}
.QnADetailAnswerTitle {
  font-weight: 500;
  font-size: 24px;
  color: var(--brand-1);
}
.QnADetailAnswerTitleWrap {
  display: flex;
}
.QnADetailAskHeader {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1368px) {
  .commonTitleContainer {
    padding: 20px 0 8px !important;
  }
  .QnADetailHeaderTitle {
    font-size: 24px;
    margin-bottom: 14px;
  }
  .QnADetailContentBox {
    padding: 13px 26px 40px;
    margin-bottom: 22px;
  }
  .QnADetailHeaderSection {
    flex-direction: column;
    margin-bottom: 32px;
    padding-bottom: 16px;
  }
  .QnADetailContentTitle {
    font-size: 18px;
  }
  .QnADetailContentCreator {
    margin-right: 12px;
  }
  .QnADetailAskHeader {
    font-size: 14px;
  }
  .QnADetailContentTitleInfoArea {
    justify-content: space-between;
    gap: 0;
    margin-top: 12px;
  }
  .QnADetailContentButtons {
    font-size: 14px;
  }
  .QnADetailReplyEmptyContent {
    margin-top: 48px;
  }
  .QnADetailAnswerTitle {
    font-size: 18px;
  }
  .commonDetailContent {
    margin: 0;
  }
  .QnADetailReplyCreateTitle {
    font-size: 17px;
  }
}
