.alarm_listener {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.alarm_icon_wrapper {
  position: relative;
  display: inline-block;
}

.alarm_icon {
  width: 24px;
  height: 24px;
  fill: #333; /* 아이콘 색상 */
}

.alarm_dot {
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  background-color: red; /* 붉은 점 */
  border-radius: 50%; /* 원형 */
  border: 2px solid white; /* 테두리 추가로 더 뚜렷하게 */
}
