/* Main Home Page Root 스타일 */
.main_home_page {
  /* height: calc(100vh - 60px); */
  /* height: auto; */
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}

.main_home_page .swiper-wrapper,
.main_home_page .swiper-slide {
  height: auto;
  /* max-height: calc(100vh - 60px); */
}

/* Swiper 컨테이너 */
.main_home_page_swiper {
  width: 100%;
  height: auto;
  overflow: hidden;
}

/* Swiper 슬라이드 */
.main_home_page_slide {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  text-align: center;
  /* height: calc(100vh - 60px); */
  height: 100%;
  overflow: hidden;
}

/* Footer가 포함된 마지막 슬라이드 */
.main_home_page_footer_slide {
  height: auto;
  display: flex;
  overflow: visible;
  flex-direction: column;
  justify-content: flex-end;
  transition: height 0.5s ease-in-out;
  /* 부드러운 높이 변화 */
}

/* 마지막 슬라이드 활성화 시 높이를 줄임 */
.footer_active .main_home_page_swiper {
  height: auto;
  min-height: 70vh;
  padding-top: 10vh;
}

/* Footer를 항상 아래쪽에 배치 */
.main_home_page_footer_slide .footer {
  /* margin-top: auto; */
  width: 100%;
  height: 200px;
}

.main_home_page .swiper {
  padding-bottom: 0px !important;
  margin-bottom: 0px;
}

/* 사이드바 스타일 */
.main_home_page_sidebar {
  position: fixed;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 100;
}

.main_home_page_sidebar_item {
  font-size: 16px;
  font-weight: 600;
  color: var(--gray-4);
  text-align: right;
  cursor: pointer;
  position: relative;
  padding-right: 10px;
  transition: color 0.3s ease-in-out;
}

.non-active-white {
  color: white !important;
  /* active된 아이템을 제외한 모든 아이템의 색상을 흰색으로 변경 */
}

.main_home_page_sidebar_item.active {
  color: #1e3a8a;
  font-weight: 700;
  right: 50px;
  transform: translateX(-5px);
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.main_home_page_sidebar_item_underline {
  position: absolute;
  width: 100px;
  height: 2px;
  background-color: #1e3a8a;
  right: -100px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}

.main_home_page_sidebar_item_underline {
  opacity: 0;
  transform: translateX(-50px);
}

.main_home_page_sidebar_item.active .main_home_page_sidebar_item_underline {
  opacity: 1;
  transform: translateX(0);
}