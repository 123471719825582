.card_top_box {
  display: flex;
  justify-content: space-between;
}

.card_sub_title {
  background-color: var(--brand-4);
  color: var(--brand-1);
  display: inline-block;
  border-radius: 20px;
  padding: 4px 14px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 700;
}

.card_title {
  margin-top: 18px;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.2;
}

.card_title.thumbnail_title {
  white-space: nowrap;
  /* 줄 바꿈 방지 */
  overflow: hidden;
  /* 넘치는 내용 숨기기 */
  text-overflow: ellipsis;
  /* "..." 표시 */
}

.card_description {
  margin-top: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  display: flex;
  position: absolute;
  bottom: 16px;
}

.thumbnail_box {
  height: 126px;
  border-radius: 12px;
  overflow: hidden;
  /* 박스를 초과하는 부분 숨김 */
  display: flex;
  /* 이미지 정렬을 보조하는 유연한 레이아웃 */
  align-items: center;
  justify-content: center;
  margin: 12px 0;
}

.thumbnail_box img {
  width: 100%;
  height: 100%;
  /* 높이도 박스와 동일하게 설정 */
  object-fit: cover !important;
  /* 이미지 비율 유지하면서 박스에 꽉 채움 */
  object-position: center;
  /* 이미지 중심을 기준으로 정렬 */
}

.card_box.contents_box {
  padding: 20px 32px;
}

.region_and_period_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 125px;
}

/* 권역 */
.card_box .regionTrans {
  margin: 35px 0 60px;
  flex-grow: 1;
}

.card_box .regionTrans ul {
  display: flex;
  justify-content: flex-start;
}

.card_box .regionTrans ul li {
  background-color: #e1f0f8;
  border-radius: 18px;
  padding: 4px 10px;
  color: #3972a3;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  gap: 4px;
}

/* 신청기간 */
.card_box .period {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  align-self: flex-start;
}

.card_box .period dt {
  color: #707070;
  padding-right: 12px;
  font-size: 16px;
  font-weight: 400;
}

.card_box .period dd {
  color: #333;
}