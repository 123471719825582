/* 리뉴얼 메인 페이지 */
.main_page_wrapper {
  position: relative;
}

.main_page_wrapper::before {
  content: "";
  display: block;
  background: url("@/assets/svg/main/main_top_bg.svg") no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -1;

  /* 기본 스타일 */
  background-position: top center; /* 기본적으로 배경 이미지 상단 중앙에 위치 */
}

@media (min-width: 1024px) {
  /* min-width가 1024px 이상일 때 배경 이미지 크기 조정 */
  .main_page_wrapper::before {
    background-size: 100%; /* 배경 이미지를 25%로 축소 */
    background-position: center top; /* 배경 이미지를 가운데 위로 위치 */
  }
}

.main_page_wrapper::after {
  content: "";
  display: block;
  background: url("@/assets/svg/main/main_top_bg2.svg") no-repeat;
  background-size: auto;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 280px;
  left: -500px;
  z-index: -1; /* 콘텐츠 뒤에 배경 이미지가 오도록 설정 */

  /* 반응형 크기 조정 */
  @media (min-width: 1024px) {
    background-size: contain; /* 작은 화면에서는 이미지 전체 표시 */
    background-position: center center;
  }
  @media (min-width: 768px) {
    background-size: auto 75%; /* 이미지 높이를 75%로 조정 */
    background-position: top center; /* 이미지 위치 조정 */
  }
}

.notice_wrapper {
  background: url("@/assets/svg/main/main_bottom_bg.png") no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.tab_wrapper {
  margin: 0 auto;
  position: relative;
  background-size: cover;
  background-position: left calc(100% - 5px);
  background-color: #F0F6FBCC; /* 배경색 */
  background-image: url("@/assets/svg/main/contents_bg.png");
  background-repeat: no-repeat;
  z-index: 1;
  box-shadow: 0px -20px 30px 0px rgba(174, 193, 208, 0.10);
  padding-top: 50px;

  @media (min-width: 2500px) {
    width: 2500px;
    margin: 0 auto;
    box-shadow: none;
    background-color: transparent;
  }
}

.tab_wrapper::after {
  content: "";
  display: block;
  background: url("@/assets/svg/main/scout_bg.svg") no-repeat;
  background-size: auto;
  position: absolute;
  top: 40px;
  left: 100px;
  width: 100%;
  height: 100%;
  z-index: -1;

  @media (min-width: 2500px) {
    background-position: top left 300px;
  }
}


.mainContainer {
  background-image: url("@/assets/svg/new_main_bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 1080px;
}

.mainInner {
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 80px;
  display: flex;
  gap: 80px;
  align-items: center;
}

.mainInner > p {
  font-size: 26px;
  font-weight: 600;
  color: var(--brand-1);
  margin-bottom: 20px;
  line-height: 30px;
  position: relative;
  top: -20px;
  margin-left: 10px;
}

.mainText {
  font-size: 0;
  font-weight: 800;
  text-align: center;
}

.mainInner .main_bottom_text {
  font-size: 20px;
  color: var(--brand-1);
  font-weight: 500;
}

.mainInner .main_bottom_text > p:first-child {
  background-color: var(--brand-1);
  color: #fff;
  padding: 6px 30px;
  border-radius: 36px;
  display: inline-block;
  margin-bottom: 10px;
}

.mainInner .main_bottom_text > p:last-child {
  border: 1px solid var(--brand-1);
  color: var(--brand-1);
  padding: 6px 30px;
  border-radius: 36px;
  display: inline-block;
  margin-bottom: 10px;
}

.mainText .textBox {
  display: block;
}

.mainText .blue {
  color: var(--brand-1);
}

.mainText .green {
  color: #4b9737;
}

.mainText .yellow {
  color: #f4ba19;
}

.mainText .red {
  color: #c8241d;
}

.mainTextBox p {
  color: var(--brand-1);
  font-size: 1.625rem;
  font-weight: 600;
  line-height: 42px;
  margin-bottom: 12px;
}

/* MainProject -----------------------*/
.mainProjectContainer {
  background-image: url("@/assets/svg/main_new_bg.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: bottom;
  padding-top: 155px;
  padding-bottom: 163px;
}

.mainProjectWrapper {
  max-width: 1100px;
  margin: 0 auto;
}

.mainProjectWrapper h3 {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 21px;
  text-align: center;
  font-weight: 400;
}
.mainProjectWrapper h3 .strong {
  font-weight: 800;
}

.mainProjectWrapper .projectExplanation {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  color: var(--gray-1);
  margin-bottom: 80px;
  text-align: center;
}

.projectInfoList .projectInfoItem {
  background-image: url("@/assets/svg/projectSvg/project_box1.svg");
  background-size: 352px;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
  width: 352px;
  height: 469px;
  position: relative;
}

.projectInfoBox {
  display: flex;
  gap: 20px;
}

.projectInfoList .projectInfoItem.yellow {
  background-image: url("@/assets/svg/projectSvg/project_box2.svg");
}

.projectInfoList .projectInfoItem.red {
  background-image: url("@/assets/svg/projectSvg/project_box3.svg");
}

.projectInfoList .projectInfoItem.red .projectInfoTitle {
  background-image: url("@/assets/svg/projectSvg/project_box3.svg");
  margin-top: 20px;
}

.projectInfoItem .projectInfoName {
  background-color: var(--brand-1);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  padding: 15px 0;
  border-radius: 43px;
  width: 167px;
  position: absolute;
  right: 0;
}

.projectInfoItem .projectInfoName.yellow {
  background-color: #f4ba19;
}

.projectInfoItem .projectInfoName.red {
  background-color: var(--red-1);
}

.projectInfoItem .projectInfoIconBox {
  margin-top: 120px;
}

.projectInfoIconBox .projectInfoIcon {
  margin-bottom: 33px;
}

.projectInfoItem .projectInfoTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 16px;
}

.projectInfoItem .projectInfoContent {
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
}

.projectContentsList {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  justify-content: center;
  margin-top: 110px;
}

.projectContentsList .projectContentsItem {
  background-color: #fff;
  width: calc((100% - 2 * 24px) / 3);
  height: 170px;
  border-radius: 42px;
  position: relative;
  background-image: url("@/assets/svg/projectSvg/project_bg3.svg");
  background-repeat: no-repeat;
  background-size: 124px;
  box-shadow: 16px 16px 40px 0 rgba(56, 86, 113, 0.1);
}

.projectContentsList .projectContentsItem:nth-child(2) {
  background-image: url("@/assets/svg/projectSvg/project_bg4.svg");
  background-repeat: no-repeat;
}

.projectContentsList .projectContentsItem:nth-child(3) {
  background-image: url("@/assets/svg/projectSvg/project_bg1.svg");
  background-repeat: no-repeat;
}

.projectContentsList .projectContentsItem:nth-child(4) {
  background-image: url("@/assets/svg/projectSvg/project_bg5.svg");
  background-repeat: no-repeat;
}

.projectContentsList .projectContentsItem:nth-child(5) {
  background-image: url("@/assets/svg/projectSvg/project_bg6.svg");
  background-repeat: no-repeat;
}

.projectContentsList .projectContentsItem:nth-child(6) {
  background-image: url("@/assets/svg/projectSvg/project_bg2.svg");
  background-repeat: no-repeat;
}

.projectContentsItem .projectContentsTextBox {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 140px;
  background-color: skyblu;
}

.projectContentsTextBox .projectContentsTextTitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 36px;
  color: var(--gray-2);
  margin-bottom: 6px;
}

.projectContentsTextBox .projectContentsText {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: var(--gray-3);
}

/* MainEducation -----------------------*/
.educationWrapper {
  padding-top: 150px;
  overflow: hidden;
}

.educationTitleBox {
  max-width: 1100px;
  margin: 0 auto;
  text-align: center;
}

.educationTitleBox .educationBadge {
  color: var(--brand-1);
  font-size: 24px;
  font-weight: 500;
  display: block;
}

.educationTitleBox .educationTitle {
  font-size: 64px;
  font-weight: 800;
  color: var(--gray-1);
  margin-top: 27px;
  background-image: url("@/assets/svg/education_highlight.svg");
  display: inline-block;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 95%;
  position: relative;
  padding: 10px 15px 15px 15px;
}

.educationTitleBox .educationTitle::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("@/assets/svg/education_title_deco.svg");
  background-repeat: no-repeat;
  position: absolute;
  bottom: 70px;
  left: 100%;
}

/* MainEducation -----------------------*/

.educatioInfoContainer {
  max-width: 1100px;
  margin: 0 auto;
  padding: 90px 0;
}

.educatioInfoContainer .educationContentsBox {
  display: flex;
  align-items: center;
  gap: 130px;
  justify-content: center;
  /* outline: 2px solid red; */
}

.educatioInfoContainer .educationContentsBox .educationInfoBox {
  /* outline: 2px solid blue; */
  /* background-color: skyblue; */
}

.educatioInfoContainer .educationInfoImage {
  display: block;
  width: 424px;
}

.contentsBox .educationInfoBox {
  color: var(--gray-1);
  font-size: 20px;
  line-height: 30px;
  margin-right: 139px;
  background-color: skyblue;
}

.educationInfoBox.right {
  /* margin-left: 60px; */
}

.educationInfoBox .number {
  font-weight: 700;
}

.educationInfoBox .title {
  font-size: 32px;
  line-height: 48px;
  margin-top: 8px;
  font-weight: 600;
}

.educationInfoBox .description {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  margin-top: 20px;
}

.educationInfoBox .shorcutButton {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  color: var(--brand-3);
  font-size: 16px;
  font-weight: 500;
  gap: 10px;
}

.shorcutButton button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

.shorcutButton button img {
  width: 6px;
  height: 10px;
  display: block;
}

.participation,
.learning {
  background-image: url("@/assets/images/main/education_participation_bg.png");
}

/* MainConsortium -----------------------*/

.mainConsortiimContainer {
  /* background-color: var(--blue-gray-2); */
  /* background-image: url("@/assets/svg/consortuim_bg.svg"); */
  /* background-repeat: no-repeat; */
  /* background-position: 250px 60px; */
  /* padding-top: 122px; */
  /* height: 1000px; */
  /* padding-bottom: 30px; */
  /* height: 100vh; */
}
.swiper {
  padding-bottom: 50px;
}

.mainConsortiimContainer .sectionTitle {
  max-width: 1100px;
  margin: 97px auto 0;
  position: relative;
}

.section_title_header .section_title_info {
  position: relative;
}

.sectionTitle .introduce {
  font-size: 24px;
  font-weight: 600;
  color: var(--gray-2);
  line-height: 36px;
}

.sectionTitle .title {
  font-size: 60px;
  font-weight: 800;
  color: var(--gray-1);
  line-height: 108px;
  margin-bottom: 120px;
}

.sectionTitle .description {
  position: absolute;
  right: 0px;
  top: 5px;
  font-size: 22px;
  line-height: 36px;
  width: 55%;
  background-image: url("@/assets/svg/consortium_text_deco.svg");
  background-repeat: no-repeat;
  padding: 12px 0 0 24px;
}
.sectionTitle button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  /* gap: 17px; */
  cursor: pointer;
}

.sectionTitle .shorcutButton {
  background-color: var(--gray-7);
  padding: 16px 30px;
  border-radius: 28px;
  color: #fff;
}

.main_university_section {
  margin-bottom: 2.5rem;
}

.main_university_section > p {
  background-color: var(--brand-1);
  display: inline-block;
  padding: 5px 20px;
  color: #fff;
  border-radius: 30px;
  line-height: 30px;
}

.main_university_section .general_badge {
  background-color: var(--brand-1);
  display: inline-block;
  padding: 5px 20px;
  color: #fff;
  border-radius: 30px;
  line-height: 30px;
}

.main_university_section .vocational_badge {
  background-color: #37949a;
  display: inline-block;
  padding: 5px 20px;
  color: #fff;
  border-radius: 30px;
  line-height: 30px;
}

/* 권역별 대학교 카드섹션 */
.main_consortium_card {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 16px;
}


.main_consortium_card > li {
  width: calc((100% - 2 * 24px) / 3);
  background-color: white;
  border-radius: 16px;
  padding: 20px 28px;
  box-shadow: 16px 16px 40px 0 rgba(56, 86, 113, 0.1);
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  cursor: pointer;
}

.main_consortium_card > li > img {
  width: 94px;
}

.main_consortium_card > li > .name > p {
  display: flex;
  gap: 6px;
}

.main_consortium_card > li > .name span.general {
  color: var(--brand-1);
  border: 1px solid var(--brand-1);
}

.main_consortium_card > li > .name span.vocational {
  color: #37949a;
  border: 1px solid #37949a;
}

.main_consortium_card > li > .name span {
  display: block;
  margin-bottom: 7px;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: var(--brand-1);
  border-radius: 18px;
  border: 1px solid var(--brand-1);
  /* width: 85px; */
  text-align: center;
  /* padding: 3px 0; */
  padding: 0 11px;
  box-sizing: border-box;
}

/* SlideContainer -----------------------*/
.consortium_slide_wrapper {
  position: relative; /* 부모 컨테이너의 상대 위치 설정 */
  margin-top: 17px;
  max-width: 1100px;
  margin: 0 auto;
}

.swiperImgItem {
  position: relative;
  border-radius: 32px;
}

.swiperImage {
  width: 100%;
  display: block;
}

.consortium_btn_box {
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 150px;
  z-index: 10;
}

.consortium_btn_box > button {
  font-size: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.consortium_btn_box > button img {
  width: 54px;
}

.slideButtonPagenation {
  display: flex;
  margin-right: 36px;
  font-weight: 600;
}

.slideButtonPagenation > span {
  display: flex;
  margin-left: 5px;
}

.slidePrevNumber.currentNumber {
  color: var(--gray-2);
}

.slidePageSlash,
.slideNextNumber {
  color: #bababa;
}

/* MainLocalHeroes -----------------------*/
.localHerosWrapper {
  text-align: center;
  padding: 80px 0;
  background-image: url("@/assets/images/main/localheors_banner.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1100px;
}

.localHerosContent {
  cursor: pointer;
  max-width: 1100px;
  margin: 0 auto;
  font-size: 0;
  padding: 300px 0;
}

.universityAboutContainer {
  background-color: #f0f6fb;
}

/* MainUniversityAbout -----------------------*/
.universityContent {
  text-align: center;
  padding: 80px 0;
  max-width: 930px;
  margin: 0 auto;
}

.universityTextTitle {
  color: var(--gray-1);
  font-size: 48px;
  font-weight: 700;
  line-height: 72px;
}

.universityText {
  color: var(--gray-3);
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
  margin: 27px 0;
}

.unicersityAboutButton {
  background-color: var(--brand-1);
  padding: 16px 29px;
  border-radius: 28px;
  border: 0;
  display: flex;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
}

.unicersityAboutButton .buttonName {
  color: #fff;
  display: block;
  margin-right: 17px;
}

.unicersityAboutButton .buttonArrowIcon {
  width: 4px;
  height: 8px;
}

.topMainContainerWrap {
  overflow: hidden;
}

/* 모바일 */
@media screen and (max-width: 1280px) {
  .mainContainer {
    background-size: auto;
    height: 860px;
  }

  .mainInner {
    max-width: 90%;
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .mainInner .main_bottom_text {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .mainInner .main_bottom_text > p {
    font-size: 16px;
  }

  .mainInner > p {
    margin-bottom: 8px;
  }

  .mainContainer .mainText {
    width: 100%;
    padding-top: 36px;
  }

  .mainInner p {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
    /* width: 140px; */
  }

  /* mainProject ---------*/
  .mainProjectContainer {
    padding-top: 0;
    margin-top: 34px;
    padding-bottom: 60px;
    background-size: cover;
    background-position: center 400px;
  }

  .mainProjectWrapper {
    max-width: 80%;
  }

  .mainProjectWrapper h3 {
    font-size: 36px;
    line-height: 45px;
    text-align: center;
  }

  .mainProjectWrapper .projectExplanation {
    font-size: 16px;
    margin-bottom: 40px;
    text-align: center;
    word-break: keep-all;
  }

  .projectInfoList .projectInfoItem {
    background-image: url("@/assets/images/main/mobile_project_box1.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    width: 320px;
    height: 420px;
    margin: 0 auto;
  }

  .projectInfoList .projectInfoItem.yellow {
    background-image: url("@/assets/images/main/mobile_project_box2.svg");
  }

  .projectInfoList .projectInfoItem.red {
    background-image: url("@/assets/images/main/mobile_project_box3.svg");
  }

  .projectInfoItem .projectInfoTitle {
    font-size: 18px;
    line-height: 27px;
    margin-top: 0;
  }

  .projectInfoItem .projectInfoContent {
    font-size: 16px;
    line-height: 24px;
  }

  .projectInfoName {
    display: none;
  }

  .projectInfoItem .projectInfoIconBox {
    margin-top: 80px;
  }

  .projectInfoIconBox .projectInfoIcon {
    margin-bottom: 34px;
  }
  .projectContentsList {
    margin-top: 62px;
  }

  .projectContentsList .projectContentsItem {
    /* background-color: #d9bdb1;
    background-image: url("@/assets/images/main/mobile_project_bg3.svg");
    background-repeat: no-repeat;
    background-position: 44px center;
    background-size: 80px;
    height: 100px;
    border-radius: 26px;
    display: flex; */
    /* flex-wrap: wrap; */
    /* justify-modalContent: flex-end; */
    /* align-items: center;
    width: 360px;
    box-sizing: border-box; */
    width: 312px;
    height: 124px;
    background-image: url("@/assets/images/main/mobile_project_bg3.svg");
    background-color: #d9bdb1;
    background-size: 90px;
    background-position: 19px center;
    border-radius: 26px;
  }

  .projectContentsList .projectContentsItem:nth-child(2) {
    background-image: url("@/assets/images/main/mobile_project_bg4.svg");
    background-color: #cce6ff;
    background-position: 28px center;
  }

  .projectContentsList .projectContentsItem:nth-child(3) {
    background-image: url("@/assets/images/main/mobile_project_bg1.svg");
    background-color: #8567ff;
    background-position: 28px center;
  }

  .projectContentsList .projectContentsItem:nth-child(3) .projectContentsTextBox > p {
    color: #fff;
  }

  .projectContentsList .projectContentsItem:nth-child(4) {
    background-image: url("@/assets/images/main/mobile_project_bg5.svg");
    background-color: #cad1ff;
    background-position: 20px center;
  }

  .projectContentsList .projectContentsItem:nth-child(5) {
    background-image: url("@/assets/images/main/mobile_project_bg6.svg");
    background-color: #ffafab;
    background-position: 20px center;
  }

  .projectContentsList .projectContentsItem:nth-child(6) {
    background-image: url("@/assets/images/main/mobile_project_bg2.svg");
    background-color: #ffd35a;
    background-position: 26px 8px;
  }

  .projectContentsItem .projectContentsTextBox {
    margin-left: -8px;
  }

  .projectContentsTextBox .projectContentsTextTitle {
    font-size: 16px;
    color: #333;
    margin: 0;
  }

  .projectContentsTextBox .projectContentsText {
    font-size: 12px;
    line-height: 18px;
  }

  /* education ---------------------- */
  .educationWrapper {
    padding: 0;
    margin-top: 88px;
  }

  .educationTitleBox .educationTitle::after {
    background-size: 30px;
    bottom: 30px;
  }

  .educationTitleBox .educationBadge {
    font-size: 1rem;
  }

  .educationTitleBox .educationTitle {
    font-size: 1.5rem;
    line-height: 1.875rem;
    padding: 0;
    background-size: 100%;
    margin-top: 16px;
  }

  .educatioInfoContainer {
    max-width: 80%;
    padding: 0;
  }

  .educatioInfoContainer .educationContentsBox {
    /* background-color: pink; */
    gap: 24px;
    display: flex;
    flex-direction: column;
    padding: 56px 0;
  }

  .educationContentsBox .educationInfoImage {
    width: 110%;
  }

  .educationInfoBox {
    width: 100%;
  }

  .educationInfoBox .number {
    font-size: 20px;
  }

  .educationInfoBox .title {
    font-size: 24px;
    line-height: 36px;
    margin-top: 4px;
    margin-bottom: 12px;
  }

  .educationInfoBox .description {
    line-height: 24px;
    font-size: 16px;
    margin-top: 12px;
  }

  .educationInfoBox .shorcutButton {
    display: none;
  }

  .participation .educationInfoBox,
  .learning .educationInfoBox {
    order: 1;
  }
  .educationInfoBox.right {
    margin: 0;
    width: 100%;
  }

  /* consortium ----------------*/
  .mainConsortiimContainer {
    padding: 0;
    height: auto;
  }


  .mainConsortiimContainer .sectionTitle {
    max-width: 80%;
  }

  .main_consortium_card {
    gap: 9px;
  }

  .main_consortium_card > li {
    width: calc((100% - 2 * 9px) / 3);
    height: 124px;
    margin: 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px 0px;
  }

  .main_consortium_card > li > img {
    width: 48px;
  }

  .main_consortium_card > li > .name {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
  }

  .main_consortium_card > li > .name > p {
    display: flex;
    justify-content: center;
  }

  .main_consortium_card > li > .name span {
    font-size: 12px;
    line-height: 18px;
    padding: 0 7px;
  }

  .main_university_section > p {
    font-size: 16px;
  }

  .sectionTitle .introduce {
    font-size: 16px;
    font-weight: 400;
  }

  .sectionTitle .title {
    font-size: 36px;
    line-height: 60px;
    margin: 0;
  }

  .sectionTitle .shorcutButton {
    display: none;
  }

  .consortium_btn_box {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    margin: 0;
    position: absolute;
    bottom: -290px;
  }

  .slideButtonPagenation {
    margin: 20px;
  }

  .consortium_btn_box > button > img {
    width: 40px;
  }

  .sectionTitle button {
    gap: 0;
  }

  .localHerosWrapper {
    max-width: 80%;
    margin: 0 auto;
    background-image: url("@/assets/images/main/mobile_localheros_banner.svg");
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
  }

  .localHerosContent {
    padding: 250px;
    margin: 36px 0;
  }

  .universityContent {
    max-width: 80%;
  }

  .universityTextTitle {
    font-size: 24px;
    line-height: 36px;
  }

  .universityText {
    font-size: 16px;
    line-height: 24px;
    margin: 16px 0;
  }

  .sectionTitle .description {
    display: none;
  }

  .localHerosWrapper {
    padding: 0;
  }

  /* 리뉴얼 */
  .main_page_wrapper::after {
    width: 0;
  }

  .scout_description span {
    line-height: 1.2;
    margin-bottom: 12px;
  }

  .tab_wrapper {
    padding-top: 10px;
  }

  .tab_wrapper::after {
    bottom: -1100px;
    top: initial;
  }
}

