.fixed-container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
}

.univ_introduction_wrapper {
  display: flex;
  justify-content: space-between;
  gap: 0;
  padding: 0;
  margin: 0 auto;
}

.university_home_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
}


.university_add_upload_icon {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px; /* 원하는 너비 */
  padding: 10px;
  height: 35px; /* 원하는 높이 */
  border-radius: 10px; /* 필요시 모서리 둥글게 */
}

.university_add_upload_icon img {
  pointer-events: none; /* 이미지 자체에서 클릭 이벤트 차단 */
}


.deco_image3 {
  background-image: url("@/assets/images/university/university_bottom_bg.png");
  padding-bottom: 129px;
  padding-top: 1px;
}

.representative {
  width: 100%;
  height: 320px;
  /* background-image: url("@/assets/images/university/university_bg1.png"); */
  background-image: url("@/assets/images/university/main_jungang.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  font-size: 0;
}

.univ_content_wrapper {
  max-width: 1440px;
  margin: 0 auto;
}

.university_about_wrapper {
  margin-top: 100px;
  background-color: red;
}

.content-container .send_to_classum_mentoring {
  margin-top: 4.9vh;
  margin-right: 24.6vh;
}

.content-container .contentWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  max-width: 1440px;
  box-sizing: border-box;
  margin: 0 auto 80px;
}

.mentoringWrapper {
  margin: 100px 330px;
}

.companyWrapper {
  margin: 100px 150px;
}

.PerformanceWrapper {
  margin: 0 150px 200px;
}

.classWrapper {
  max-width: 1320px;
  margin: 168px auto 0;
}

.univ_info {
  position: relative;
}

.editButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  margin-left: 10px;
}

.editContainer {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.editTextarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  font-size: 1em;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.editButtons {
  display: flex;
  align-items: center;
}

.saveButton,
.cancelButton {
  font-size: 1em;
  padding: 5px 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.saveButton {
  color: #4caf50;
}

.cancelButton {
  color: #f44336;
}

@media screen and (max-width: 1280px) {
  /* .univ_content_wrapper {
      width: 95%;
      margin: 0 auto;
    } */

  .content-container .contentWrapper {
    flex-direction: column;
    margin-top: 60px;
  }

  .university_classum_button {
    margin-top: 40px;
  }

  .representative {
    height: 200px;
  }

  .content-container {
    width: 95%;
    margin: 0 auto;
  }

  .deco_image::after {
    top: -30px;
    right: 1;
    display: none;
  }

  .toListButton {
    width: 100%;
    margin: 30px auto;
  }

  .contentWrapper {
    min-width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .university_home_wrapper {
    margin-top: 0;
  }

  .modify_content_area p {
    font-size: 16px;
  }
  .university_classum_button {
    margin-top: 3vh;
    width: 100%;
  }

  .content-container {
    display: flex;
    flex-direction: column;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 40px;
    min-width: 100% !important;
    width: 40% !important;
    box-sizing: border-box;
  }
}
