.swiper_pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.swiper_pagination .swiper_bullet_group {
    display: flex;
    gap: 6px;
}

.swiper_pagination .swiper_btn_group {
    display: flex;
    gap: 18px;
    align-items: center;
}

.swiper_pagination .swiper_btn_group img {
    display: block;
}

.swiper_pagination .swiper_page {
    display: flex;
    gap: 3px;
    color: var(--gray-4);
}

.swiper_page .current {
    color: var(--gray-1);
    font-weight: 500;
}

.swiper {
    padding-bottom:32px !important;
}
