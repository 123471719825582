#footer {
  background-color: white;
  border-top: solid 1px var(--gray-6);
  padding: 28px 0 36px;
}

#footer .footer_inner {
  display: flex;
  justify-content: space-between;
  max-width: 1320px;
  margin: 0 auto;
}

#footer .footer_navigation {
  display: flex;
  margin-bottom: 50px;
  gap: 40px;
}

#footer .footer_navigation a {
  color: #fff;
  font-size: 16px;
}

#footer .content {
  width: 100%;
}

#footer .content .footer_logo {
  width: 108px;
  margin-right: 90px;
}

#footer .content .addressContainer {
  display: flex;
  flex-flow: column;
  gap: 8px;
  color: #8f8f8f;
  font-size: 16px;
  line-height: 1.5;
  /* 텍스트가 길어져서 줄바꿈 할 때 단어를 쪼개서 줄바꿈 하지 말라 */
  word-break: keep-all;
  /* 텍스트가 길어질 경우 텍스트를 끊어서 줄바꿈을 해라 */
  overflow-wrap: break-word;
}

.address_content {
  display: flex;
  gap: 24px;
  /*margin-top: 16px;*/
}

.fnb {
  display: flex;
  align-items: center;
  gap: 24px;
}

.infoLink {
  color: #8f8f8f;
  font-size: 16px;
}

.site_link {
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
  bottom: 8px;
  flex-wrap: wrap;
}

.site_link li a {
  display: block;
}
.site_link li a img {
  width: 100%;
}
.site_link li .footer_logo_1 {
  width: 79px;
}
.site_link li .footer_logo_2 {
  width: 88px;
}
.site_link li .footer_logo_3 {
  width: 165px;
}

.footerContentInner {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.footerScoutTitle {
  font-weight: 500;
}
.footerLogos {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

@media only screen and (max-width: 1280px) {
  #footer {
    padding-top: 30px;
  }
  #footer .footer_inner {
    width: 90%;
    padding: 0 5%;
  }
  #footer .footer_navigation {
    display: none;
  }

  #footer .content {
    display: block;
  }
  #footer .content .addressContainer {
    font-size: 14px;
  }
  #footer .content .footer_logo {
    margin-right: 10px;
    margin-bottom: 12px;
  }
  .fnb {
    /* width: calc((100% - 2 * 10px) / 3); */
    gap: 12px;
    flex-wrap: wrap;
  }
  .infoLink {
    font-size: 13px;
  }

  #footer .site_link {
    flex-direction: row;
    align-items: center;
  }

  .site_link li .footer_logo_1 {
    width: 66px;
  }
  .site_link li .footer_logo_2 {
    width: 72px;
  }
  .site_link li .footer_logo_3 {
    width: 140px;
  }

  /* 푸터 수정 후 반응형  */
  .footerLogos {
    gap: 24px;
    flex-direction: column;
    justify-content: center;
  }

  .footerContentInner {
    flex-direction: column;
    gap: 24px;
  }
}

@media only screen and (max-width: 1024px) {
  .address_content {
    flex-direction: column;
    gap: 4px;
    font-weight: 300;
  }
}

@media only screen and (max-width: 640px) {
  #footer .footer_inner {
    padding: 0;
  }
}
