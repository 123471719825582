.carousel_pagination {
    width: 424px;
    margin: 60px auto 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.carousel_pagination .carousel_bullet_group {
    display: flex;
    gap: 6px;
    align-items: center;
}

.bullet {
    width: 10px;
    height: 10px;
    background-color: var(--gray-1);
    border-radius: 100%;
    transition: width 0.3s ease, background-color 0.3s ease;
}

.bullet_active {
    width: 20px;
    height: 10px;
    border-radius: 30px;
    background-color: var(--gray-1);
}

.carousel_pagination .carousel_btn_group {
    display: flex;
    align-items: center;
    gap: 18px;
}

.carousel_pagination .carousel_btn_group img{
    display: block;
}

.carousel_btn_group .carousel_page {
    display: flex;
    gap: 3px;
    color: var(--gray-4);
}

.carousel_btn_group .current {
    color: var(--gray-1);
    font-weight: 500;
    width: 10px;
}

@media screen and (max-width: 1280px) {
    .carousel_pagination {
        width: 90%;
    }
}
