.loading_spinner {
  width: 100%;
  /* margin: 0 auto; */
  position: relative;
  /* background-color: #3b3b3b; */
  border: 1px solid var(--brand-1);
  border-radius: 8px;
}

.loading_spinner p {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: -20px;
  font-size: 16px;
  line-height: 1.5;
  color: var(--brand-1);
  /* color: white; */
  font-weight: 600;
  z-index: 1000;
}

.loading_spinner svg {
  width: 120px;
}

.loading_spinner.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  /* backdrop-filter: blur(6px); */
  /* backdrop-filter: blur(6px) brightness(0.8); */
  /* backdrop-filter: blur(6px) sepia(0.3); */
}

.loading_spinner.fixed svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
  background-color: rgba(255, 255, 255, 0.5) !important;
  padding: 20px;
  backdrop-filter: blur(10px) contrast(1.2);
  /* backdrop-filter: blur(10px) contrast(1.3); */
  border-radius: 12px;
}

.loading_spinner.fixed p {
  font-size: 24px;
  margin-top: -30px;
}
