.SubPageTitle_container {
  display: flex;
  justify-content: center;
  /*padding-bottom: 53px;*/
  /*border-bottom: 1.5px solid var(--table-line-1);*/
  width: 100%;
  position: relative;
}

.SubPageTitle {
  display: flex;
  flex-direction: column;
  gap: 14px;
  max-width: 1320px;
  width: 100%;
  margin-top: 53px;
}
.SubPageTitle .subTitle {
  font-size: 16px;
  font-weight: 500;
  color: var(--brand-1);
  line-height: 1.5;
  /* margin-bottom: 14px; */
}
.SubPageTitle .title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.5;
}

@media screen and (max-width: 1280px) {
  .SubPageTitle {
    width: auto;
    margin-top: 44px;
  }
  .SubPageTitle .subTitle {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .SubPageTitle .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
  }
}
