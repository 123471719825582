.popup .main_img {
  width: 100%;
}

.popup .OverlaySection {
  position: static;
  background: none;
  z-index: auto;
}

/* 임시 공지를 위해 notice 라는 클래스를 추가해서 모달이 가능하도록 함 */
.popup.notice .OverlaySection {
  position: fixed;
  z-index: 200;
  background: none;
}

.popup .OverlaySection .modal_wrapper {
  position: fixed;
  box-shadow: 10px 10px 30px 0 rgba(0, 0, 0, 0.3);
  border: 1px solid #ccc;
  border-radius: 8px 8px 14px 14px;
  z-index: 111;
}

.popup .popup_content {
  margin-bottom: 24px;
}

.popup .input_container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.popup .modal_wrapper .modal_content {
  padding: 20px;
  max-height: none;
}

.popup .popup_checkbox {
  margin: 0;
}

.popup .popup_label {
  margin-left: 4px;
  font-size: 16px;
}

/* 팝업 뒤 어두운 배경(오버레이) */
.popup_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* 어두운 반투명 배경 */
  z-index: 110;
  /* 팝업보다 낮은 z-index */
  display: none;
  /* 기본적으로 숨김 */
}

/* 팝업이 열려있을 때 오버레이 표시 */
.popup_overlay.active {
  display: block;
}

@media screen and (max-width: 1024px) {
  .popup .OverlaySection .modal_wrapper {
    border: none;
    border-radius: 0;
  }

  .popup .modal_content_wrapper {
    height: auto !important;
    min-height: auto !important;
  }
}