/* 권역별 컨소시엄 탭 */
.main_content_box {
    display: flex;
    justify-content: space-between;
    gap: 62px;
    padding-bottom: 32px;
}

.content_img_box {
    box-shadow: 0 4px 30px 0 rgba(174, 193, 208, 0.20);
    height: 100%;
}

.content_img_box img {
    width: 100%;
    display: block;
}

.consortium_content_box {
    width: 98%;
    display: flex;
    flex-direction: column;
    /*gap: 54px;*/
}

.consortium_content_box p {
    display: flex;
    align-items: center;
    gap: 14px;
    font-size: 16px;
    font-weight: 600;
    color: var(--brand-1);
    margin-bottom: 12px;
}

.consortium_content_box ul {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.consortium_content_box ul li {
    padding: 10px 24px;
    border-radius: 8px;
    background: #F8F9FA;
    display: flex;
    gap: 7px;
    line-height: 1.5;
}

.feature_content_box {
    margin-top: 54px;
}

.feature_btn_group {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

.feature_btn_group button {
    border-radius: 8px;
    background-color: var(--brand-5);
    background-image: url("@/assets/svg/main/button_illust.svg");
    background-size: contain;
    background-position: 80%;
    background-repeat: no-repeat;
    width: 100%;
    padding: 28px 24px;
    display: flex;
    justify-content: space-between;
    flex: calc(50% - 12px);
}

.tab_text_box {
    padding: 40px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid var(--brand-4);
    box-shadow: 0px 4px 30px 0px rgba(174, 193, 208, 0.20);
    margin-top: 32px;
    display: flex;
    align-items: center;
    gap: 44px;
    line-height: 1.5;
}

.tab_content {
    position: relative;
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.tab_content.active {
    opacity: 1;
}

.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 18px;
    font-weight: bold;
}

.local_hero_list {
    display: flex;
    align-items: center;
}

.local_hero_list a {
    display: flex;
    justify-content: space-between;
    gap: 14px;
    width: 100%;
}

.local_hero_list .local_title {
    font-weight: 600;
    margin-right: 10px;
}

@media screen and (max-width: 1280px) {
    .main_content_box {
        display: initial;
    }

    .consortium_content_box {
        margin-top: 30px;
    }

    .tab_text_box {
        padding: 20px;
        /*display: initial;*/
        flex-direction: column;
        gap: 8px;
        font-size: 16px;
        text-align: center;
    }

    .feature_btn_group button {
        padding: 20px;
    }

}
