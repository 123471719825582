.university_list {
  display: none;
}

.university_list.active {
  display: block;
}

.consortiumContainer {
  background-image: url("@/assets/images/university/consortium_bg_img1.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 1080px;
  position: relative;
}

.consortiumInner {
  max-width: 1320px;
  margin: 0 auto;
  padding-top: 70px;
}

.consortiumInformation {
  background-color: #fff;
  width: 560px;
  height: 720px;
  border-radius: 0 30px 30px 30px;
  box-shadow: 16px 4px 40px 0 rgba(15, 69, 117, 0.1);
}

.consortiumInner .introduceTitle {
  font-weight: 800;
  font-size: 48px;
  line-height: 72px;
  color: var(--brand-1);
}

.consortiumInformation .region {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  background-color: var(--gray-6);
  color: #fff;
  border-radius: 0 30px 0 0;
}

.consortiumInformation .universityAboutTitle {
  position: relative;
  display: flex;
  text-align: center;
  cursor: pointer;
}

.consortiumInformation .universityAboutTitle p {
  width: 50%;
  padding: 8px 0;
}

.consortiumInformation .universityAboutTitle p.general_active {
  background-color: var(--brand-1);
}

.consortiumInformation .universityAboutTitle p.vocational_active {
  background-color: #37949a;
  border-radius: 0 30px 0 0;
}

.consortiumInformation .toUniversityAboutButton {
  border: 0;
  background-color: transparent;
  position: absolute;
  top: 28px;
  right: 40px;
  color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 24px;
  cursor: pointer;
}

.toUniversityAboutButton .buttonText {
  color: #fff;
}

.toUniversityAboutButton .buttonArrowIcon {
  width: 5px;
  height: 8px;
  margin-left: 10px;
  margin-top: -2px;
}

.consortiumInformation .introduction {
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-3);
  margin-bottom: 18px;
  border-bottom: 1px solid #e8e8e8;
  padding: 32px 42px;
  letter-spacing: -0.3px;
  height: 150px;
  overflow-y: scroll;
  box-sizing: border-box;
}

/*특정 부분 스크롤바 없애기*/

.introduction {
  -ms-overflow-style: none;
}
.introduction::-webkit-scrollbar {
  width: 7px;
}
.introduction::-webkit-scrollbar-track {
  background-color: transparent;
}

.introduction::-webkit-scrollbar-thumb {
  background: #dddd;
  background-clip: padding-box;
  border: 1px solid transparent;
}

.consortiumInformation .listItems {
  padding: 0 42px;
}

.universityMapInfoBox {
  display: flex;
  justify-content: space-between;
  /*background-image: url("@/assets/images/university/consortium_map.png");*/
  background-repeat: no-repeat;
  position: relative;
  background-position: 20px 65px;
}

.buttonStyle > button {
  border: 0;
  /*background-color: transparent;*/
  background-color: var(--gray-4);
  border: 0;
  border-radius: 17px;
  color: #fff;
  padding: 4px 12px;
  line-height: 24px;
  font-size: 16px;
  cursor: pointer;
}

.buttonStyle .mapIcon {
  position: relative;
  left: 18px;
  bottom: 10px;
}

.buttonStyle > button.active {
  background-color: var(--brand-1);
  transition: all 0.8s;
}

.buttonStyle > button:hover {
  background-color: var(--brand-1);
  transition: all 0.8s;
}

.mapButtons .capital {
  position: absolute;
  top: 220px;
  left: 120px;
}

.mapButtons .chungcheong {
  position: absolute;
  top: 310px;
  left: 160px;
}

.mapButtons .honam_jeju {
  position: absolute;
  bottom: 240px;
  left: 115px;
}

.mapButtons .dongnam {
  position: absolute;
  bottom: 230px;
  left: 285px;
}

.mapButtons .kangwon {
  position: absolute;
  top: 205px;
  left: 315px;
}

.consortiumInformation {
  position: relative;
  top: -40px;
}

.consortiumInformation .title {
  font-weight: 600;
  font-size: 20px;
  color: var(--brand-1);
  margin-bottom: 12px;
  margin-top: 13px;
  margin-left: 12px;
}

.consortiumInformation .listItems {
  margin-top: 18px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
}

.listItems .iconBox {
  display: flex;
  align-items: center;
}

.listItems .goal_image_box {
  text-align: center;
  width: 300px;
  height: 200px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
}

.listItems .goal_image_box img {
  width: 100%;
  height: 100%;
}

/* 배경색과 돋보기 아이콘을 위한 ::before 가상 요소 추가 */
.listItems .goal_image_box:hover::before {
  content: "";
  background-color: #333;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  z-index: 0;
}

.listItems .goal_image_box:hover::after {
  display: block;
  content: "";
  width: 26px;
  height: 26px;
  background-image: url("@/assets/svg/plus_img_icon.svg");
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 추진체계 이미지 모달 */
.goal_modal_img {
  /* 모달이 공통으로 변경되어 주석합니다 -성찬 */
  /* display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 1000px;
  height: auto; */
  width: 100%;
}

/* .goal_modal_img::after {
  display: block;
  modalContent: "";
  width: 200px;
  height: 200px;
  background-color: var(--brand-1);
  position: relative;
  top: 0;
  height: 0;
} */

.universities .universitiesList {
  display: flex;
  margin-bottom: 8px;
  margin-top: 10px;
  gap: 14px;
}

.universities .universitiesList .universityBox {
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  cursor: pointer;
  line-height: 18px;
}

.universities .hilightTitle:first-child {
  color: var(--brand-1);
  font-weight: 700;
}

.universities .hilightTitle:first-child::after {
  content: "";
  background-color: var(--brand-1);
  width: 100%;
  height: 2px;
  display: block;
  position: relative;
  top: -3px;
}

.universities .universitiesList .universityBox .text {
  width: 100%;
  box-sizing: border-box;
}

.universitiesList .badge {
  /* width: 42px;
  height: 24px; */
  background-color: var(--brand-1);
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  border-radius: 16px;
  padding: 2px 10px;
  height: 28px;
  cursor: pointer;
}

.universitiesList .vocational {
  background-color: #37949a;
}

/* Tab List ------------------------*/
.universityTabListContainer {
  /* background-color: #00997d; */
  padding-bottom: 100px;
}

.tabList {
  display: flex;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 40px;
  border-bottom: 1px solid #e8f0f3;
  padding-bottom: 25px;
}

.tabList .generalTab {
  margin-right: 32px;
}

/* 활성 탭 스타일 */
.tabList li.active {
  color: var(--brand-1);
}

.tabList li.active::after {
  content: "";
  display: block;
  background-color: var(--brand-1);
  width: 100%;
  height: 4px;
  position: relative;
  top: 25px;
}

/* 비활성 탭 스타일 */
.tabList li {
  color: var(--gray-5);
  cursor: pointer;
}

.universityNameButton {
  display: flex;
  gap: 12px;
  margin-bottom: 100px;
}

.universityName {
  border: none;
  background-color: #e1f0f8;
  color: #3972a3;
  padding: 7px 18px;
  border-radius: 22px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  line-height: 30px;
}

.universityName:hover {
  /* hover 시의 스타일 변경 */
  background-color: var(--brand-1) /* 변경하고자 하는 배경색 */;
  color: #fff /* 변경하고자 하는 텍스트 색상 */;
  transition: all 0.5s;
}

.universityName.activeBtn {
  background-color: var(--brand-1);
  color: #fff;
}

.universitySelectWrapper {
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: contain;
  max-width: 1320px;
  margin: 0 auto;
  padding-top: 78px;
  position: relative;
}

.ParticipatingUniversities {
  font-size: 42px;
  font-weight: 800;
  padding-bottom: 72px;
  /* margin: 50px 0 72px 0;
  padding-top: 28px; */
}

.selectedUniversityCard {
  display: flex;
  flex-direction: row;
}
.selectedUniversityCard > ul {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 2%;
  width: 100%;
  box-sizing: border-box;
}

.selectedUniversityCard > ul > li {
  background-color: #fff;
  width: 32%;
  border-radius: 16px;
  box-shadow: 16px 16px 40px 0 rgba(56, 86, 113, 0.1);
  border: 1px solid #dae6eb;
  padding: 21px 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 36px;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  cursor: pointer;
}

.selectedUniversityCard > ul > li img {
  width: 128px;
}

.selectedUniversityCard > ul > li .name span {
  margin-bottom: 7px;
  display: block;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: var(--brand-1);
  border-radius: 18px;
  border: 1px solid var(--brand-1);
  width: 85px;
  text-align: center;
  padding: 3px 0;
  box-sizing: border-box;
}

.selectedUniversity h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
}

.consortium_about_blue_circle_1 {
  position: absolute;
  top: 1250px;
  left: -35px;
  right: auto;
  width: 152px;
  height: 152px;
  z-index: 0;
}

.consortium_about_blue_circle_2 {
  position: absolute;
  top: 1420px;
  left: auto;
  right: -52px;
  width: 204px;
  height: 204px;
  z-index: 0;
}

.consortium_about_image1_container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.consortium_about_image1 {
  position: absolute;
  top: 160px;
  left: 75%;
  width: 332px;
  height: 92px;
  z-index: 0;
}

.consortium_about_image2_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.consortium_about_image2 {
  position: absolute;
  top: 675px;
  left: 62%;
  width: 223px;
  height: 83px;
  z-index: 0;
}

/* 모바일 */
@media screen and (max-width: 1280px) {
  .consortiumContainer {
    /* height: 640px; */
    background-image: none;
    background-color: #e3f2ff;
  }

  .consortiumInner {
    width: 90%;
    margin: 0 auto;
  }

  .consortiumInner .introduceTitle {
    font-size: 32px;
    line-height: 48px;
  }

  .universityMapInfoBox {
    flex-direction: column;
    justify-content: center;
    background-size: 312px;
    /* background-position: center 60px; */
  }

  .mapButtons .capital {
    top: 140px;
    left: 50px;
  }

  .mapButtons .chungcheong {
    top: 220px;
    left: 80px;
  }

  .mapButtons .honam_jeju {
    top: 290px;
    left: 40px;
  }

  .mapButtons .kangwon {
    top: 140px;
    left: 160px;
  }

  .mapButtons .dongnam {
    top: 290px;
    left: 180px;
  }

  .consortiumInformation {
    top: 500px;
    width: 100%;
    height: 780px;
  }

  .consortiumInformation .listItems {
    padding: 0 16px;
  }

  .consortiumInformation .region {
    /* padding: 5px 16px; */
    font-size: 18px;
  }

  .consortiumInformation .title {
    font-size: 18px;
    margin-left: 10px;
  }

  .listItems .goal_image_box {
    width: auto;
  }

  .listItems .goal_image_box img {
    width: 280px;
    margin: 0 auto;
  }

  .universitySelectWrapper {
    padding-top: 480px;
    width: 90%;
    margin: 0 auto;
  }

  .ParticipatingUniversities {
    font-size: 32px;
    line-height: 48px;
    padding-bottom: 50px;
  }

  .selectedUniversityCard > ul > li {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    gap: 28px;
  }

  .selectedUniversityCard > ul > li .name span {
    font-size: 12px;
    line-height: 18px;
    width: 64px;
  }

  .selectedUniversityCard > ul > li img {
    width: 72px;
  }

  .goal_modal_img {
    width: 90%;
  }
}

@media screen and (max-width: 640px) {
  .consortium_about_image1 {
    position: absoulte;
    top: 563px;
    left: 60%;
    width: 200px;
  }

  .consortium_about_image2 {
    position: absolute;
    top: 1370px;
    left: 50%;
    width: 200px;
  }
}
