.alarm-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.alarm-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 60%;
  height: 70%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  gap: 12px;
}

.alarm-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.alarm-modal-header h2 {
  margin: 0;
  font-size: 16px;
}

.unread-count {
  display: inline-block;
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 12px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  margin-left: 8px;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.alarm-modal-body {
  max-height: 90%;
  overflow-y: auto;
}

.alarm-item {
  /* border-bottom: 1px solid #ddd; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  line-height: 2;
  border-radius: 8px;
  padding: 20px 30px;
}

.alarm-item.unread {
  background-color: #fff; /* 안 읽은 알림 */
}

.alarm-item.read {
  background-color: #f0f0f0; /* 읽은 알림 */
  color: var(--gray-5);
}

.alarm-modal-body .delete-button {
  background: none;
  border: none;
  font-size: 16px;
  color: red;
  cursor: pointer;
  margin-bottom: auto;
}

.alarm_item_created_at {
  font-weight: 600;
  align-items: center;
}

.alarm_item_message {
  font-size: 18px;
}

.unread-dot {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  margin-left: 8px;
  display: inline-block;
  align-items: center;
}

.alarm_not_exist {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: 100%;
  min-height: 500px;
}

.alarm_not_exist_text {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: var(--gray-4);
}
