/* 인풋 라벨 스타일 */
.InputWithLabel {
  width: 100%;
}
.InputWithLabel input {
  width: 100%;
  height: 52px;
  padding: 11px 19px;
  background-color: #f8f9fa;
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 6px;
  color: #505050;
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0);
}

.InputWithLabel input:focus {
  border: 1px solid #333;
}

.InputWithLabel label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: #505050;
}
.InputWithLabel .input_wrapper {
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;
}

.InputWithLabel button {
  display: block;
  width: 127px;
  height: 42px;
  bottom: 5px;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  color: #fff;
  background-color: #024c8c;
  border-radius: 6px;
  font-size: 16px;
  padding: 0;
  margin: 0;
  outline: none;
  border: 0;
  cursor: pointer;
}

.InputWithLabel .validate {
  margin-top: 10px;
  font-size: 14px;
}
.InputWithLabel .validate.success {
  color: rgb(1, 188, 1);
}
.InputWithLabel .validate.warning {
  color: #f44336;
}

/* 수정 할 수 있는 input 스타일 */
.InputModifyStyle {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  color: #333333;
  line-height: 1.5;
}

.InputModifyStyle label {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: #707070;
}

.InputModifyStyle button {
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 6px 10px;
  background: none;
  gap: 4px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  color: #707070;
}

.InputModifyStyle .input_wrapper {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  gap: 12px;
}

.InputModifyStyle .input_wrapper input {
  flex: 1;
  margin: 0;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 6px;
  border: 1px solid #ccc;
  outline: none;
}

.InputModifyStyle .input_wrapper button {
  /* position: absolute; */
  /* right: 10px; */
  background-color: var(--brand-1);
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
