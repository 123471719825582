.globalTabContainer {
  transition: all ease-in-out .15s;
  position: relative;
  display: flex;
  gap: 32px;
  font-weight: 600;
  font-size: 20px;

  /* theme: managementModal styles */
  &.managementModal {
    gap: 20px;
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.globalTabItem {
  transition: all ease-in-out .15s;
  padding-bottom: 16px;
  color: var(--gray-5);
  border-bottom: 4px solid transparent;
  cursor: pointer;
  position: relative;
  z-index: 1;

  &.selected {
    color: var(--brand-1);
    border-bottom: 4px solid var(--brand-1);
  }

  /* theme: managementModal styles */
  &.managementModal {
    border-bottom: 2px solid transparent;

    &.selected {
      color: var(--brand-1);
      border-bottom: 2px solid var(--brand-1);
    }
  }
}

/* 디바이더 style */
.global_tab_bottom_divider {
  width: 100%;
  height: 1px;
  background-color: var(--table-line-2);
  bottom: 0;
  position: absolute;
}

/* mobile */
@media only screen and (max-width: 1024px) {
  /* theme: common styles */
  .globalTabContainer {
    margin-top: 32px;
    font-size: 18px;

    &.managementModal {
      margin-top: 28px;
      font-size: 18px;
      display: flex;
      justify-content: space-between;
      gap: 0;
    }
  }

  .globalTabItem {
    &.managementModal {
      font-size: 16px;
      padding: 0 15px 16px 15px
    }
  }

  /* theme: managementModal styles */

}
