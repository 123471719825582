.home_fourth_wrapper {
  background: url("@/assets/images/home/fourth/fourth_background.png") no-repeat;
  background-size: contain;
  width: 100%;
  height: calc(100vh - 60px);
  display: flex;
  align-items: center;
}

.home_fourth_contents_wrapper {
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
}

.home_fourth_contents_top_title {
  position: relative;
  text-align: center;
}

.home_fourth_contents_top_title h3 {
  font-size: 32px;
  font-weight: 700;
  position: relative;
}

.home_fourth_contents_edu_contents_wrapper {
  display: flex;
  flex-direction: row;
  gap: 48px;
  justify-content: space-between;
  margin-top: 60px;
  max-width: 1320px;
}

.home_fourth_contents_notice {
  margin-top: 100px;
  margin-bottom: 100px
}

.home_fourth_contents_gallery,
.home_fourth_contents_video,
.home_fourth_contents_notice {
  width: 100%;
}

.home_fourth_contents_gallery_top,
.home_fourth_contents_video_top,
.home_fourth_contents_notice_top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.home_fourth_contents_gallery_sub,
.home_fourth_contents_video_sub,
.home_fourth_contents_notice_sub {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.home_fourth_contents_gallery_title,
.home_fourth_contents_video_title,
.home_fourth_contents_notice_title {
  font-size: 24px;
  font-weight: 700;
  display: flex;
  align-items: flex-start;
}

.home_fourth_contents_gallery_more,
.home_fourth_contents_video_more,
.home_fourth_contents_notice_more {
  font-size: 14px;
  color: var(--gray-6);
}

.home_fourth_contents_gallery_item,
.home_fourth_contents_video_item {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 24px;
  justify-content: space-between;
  max-width: 100%;
  overflow: hidden;
}

.home_fourth_contents_gallery_item_card,
.home_fourth_contents_video_item_card {
  position: relative;
  flex: 1 1 calc(25% - 8px);
  overflow: hidden;
  height: 200px;
  text-align: center;
  cursor: pointer;
}

.home_fourth_contents_gallery_item_card img,
.home_fourth_contents_video_item_card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.home_fourth_plus_button {
  background-color: var(--gray-6);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  padding: 5px;
  border-radius: 7px;
  stroke: white;
}

.home_fourth_contents_gallery_item_card::after,
.home_fourth_contents_video_item_card::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 200px;
  background: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.home_fourth_contents_gallery_item_card .hover_text,
.home_fourth_contents_video_item_card .hover_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
}

.home_fourth_contents_gallery_item_card .hover_text_date,
.home_fourth_contents_video_item_card .hover_text_date {
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
}

.home_fourth_contents_gallery_item_card:hover::after,
.home_fourth_contents_video_item_card:hover::after {
  opacity: 1;
}

.home_fourth_contents_gallery_item_card:hover .hover_text,
.home_fourth_contents_video_item_card:hover .hover_text {
  opacity: 1;
}

.home_fourth_contents_gallery_item_card:hover img,
.home_fourth_contents_video_item_card:hover img {
  transform: scale(1.05);
}

.home_fourth_contents_notice_item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 24px;
  margin-top: 24px;
  justify-content: space-between;
  max-width: 100%;
  overflow: hidden;
}

.home_fourth_contents_notice_item_card {
  position: relative;
  flex: 1 1 calc(33.33% - 24px);
  max-width: calc(33.33% - 24px);
  border-radius: 24px;
  overflow: hidden;
  cursor: pointer;
  text-align: left;
  padding: 32px;
  min-width: 250px;
  border: 1px solid var(--table-line-1);
  background-color: white;
}

.notice_item_kind {
  font-size: 16px;
  font-weight: 600;
}

.notice_item_title {
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  line-height: 1.5;
}

.notice_item_date {
  font-size: 16px;
  color: var(--gray-4);
  margin-top: 24px;
}

@media screen and (max-width: 1280px) {
  .home_fourth_wrapper {
    height: auto;
    padding: 0 24px;
    padding-top: 52px;
    background-image: url("@/assets/images/home/fourth/fourth_tablet_background.png");
    position: relative;
  }

  .home_fourth_wrapper::after {
    content: '';
    display: block;
    width: 100%;
    height: 200px;
    background-image: url("@/assets/images/home/fourth/main_last_section_tablet_line.png");
    background-repeat: no-repeat;
    position: absolute;
    background-size: contain;
    z-index: -1;
    bottom: 0;
    left: 0;
  }

  .home_fourth_contents_edu_contents {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home_fourth_contents_edu_contents_wrapper {
    flex-direction: column;
    width: 628px;
    justify-content: center;
    align-items: center;
  }

  .home_fourth_contents_notice {
    margin-top: 48px;
    margin-bottom: 75px;
  }

  .home_fourth_contents_top_title h3::after {
    content: '';
    width: 97px;
    height: 68px;
    position: absolute;
    bottom: -10px;
    right: 0;
    /* background-image: url("@/assets/images/home/fourth/main_fourth_people.png"); */
    background-size: cover;
  }


  .notice_item_title {
    margin-top: 0;
    font-size: 16px;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .home_fourth_contents_notice_item_card {
    max-width: none;
    padding: 16px;
    border-radius: 8px;
  }

  .notice_item_date {
    margin-top: 4px;
    font-size: 14px;
  }
}

@media screen and (max-width: 640px) {
  .home_fourth_wrapper {
    background-image: none;
  }

  .home_fourth_wrapper::after {
    content: '';
    width: 100%;
    height: 105px;
    background-image: url("@/assets/images/home/fourth/main_last_section_line.png");
    background-size: contain;
    bottom: 0;
    left: 0;
  }

  .home_fourth_contents_top_title h3 {
    font-size: 16px;
    position: relative;
    text-align: start;
  }

  .home_fourth_contents_top_title::after {
    content: '';
    width: 97px;
    height: 68px;
    position: absolute;
    bottom: -10px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("@/assets/images/home/fourth/main_fourth_people.png");
    background-size: cover;
  }

  .home_fourth_contents_top_title h3::after {
    content: none;
  }

  .home_fourth_contents_gallery_item_card,
  .home_fourth_contents_video_item_card {
    height: 108px;
  }
}