/* --- 스타일드 대신 작성 --- */
.Search_SubTitle {
  padding-bottom: 21px;
}
.Search_SubTitle_inner {
  width: 1320px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 20px; */
}
.Search_SubTitle_inner .left_box {
  display: flex;
}

.Search_SubTitle_inner .left_box img {
  width: 8px;
  margin-right: 10px;
}

.Search_SubTitle_inner .description {
  display: inline-block;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--brand-1);
}

.Search_SubTitle form {
  display: flex;
  justify-content: flex-end;
}

.Search_SubTitle form button {
  border: 0;
  background-color: transparent;
  position: relative;
  padding: 0px;
}

.Search_SubTitle form button img {
  position: absolute;
  top: -16px;
  right: 28px;
  width: 26px;
  height: 26px;
  cursor: pointer;
}

/* input */
.Search_SubTitle .SearchInputTag {
  box-sizing: border-box;
  width: 424px;
  height: 42px;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 0 24px;
}

.Search_SubTitle .SearchInputTag::placeholder {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  color: #aaafb9;
}

/* --- 스타일드 대신 작성 --- */

.Search_SubTitle .right_box {
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Search_SubTitle .right_box > div.customSelectBox {
  margin-right: 8px;
  position: relative;
}

.Search_SubTitle .right_box > div.customSelectBox > select {
  background-color: #fff;
  padding: 8px 24px;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  margin-right: 8px;
}
/* 현재 z-index를 올려서 강제로 보이게 했더니 해당 img는 클릭되지 않음 */
.Search_SubTitle .right_box > div.customSelectBox .icon_arrow {
  color: #707070;
  position: absolute;
  z-index: 5;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (max-width: 1280px) {
  .Search_SubTitle_inner {
    display: block;
  }
  .Search_SubTitle_inner .left_box {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 640px) {
  .Search_SubTitle .right_box {
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
  }
  .Search_SubTitle .SearchInputTag {
    width: 100%;
  }
  .Search_SubTitle .right_box {
    justify-content: flex-start;
  }
  .searchInput_box {
    width: 100%;
  }
  .Search_SubTitle_inner .description {
    font-size: 16px;
  }
}
