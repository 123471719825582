.home_first_wrapper {
  background-color: #def0ff;
  background-size: cover;
  background-position: top center;
  height: calc(100vh - 60px);
  width: 100%;
  /* height: auto; */
  position: relative;
  z-index: 1;
}

.home_first_wrapper::before {
  background: url("@/assets/images/home/firstPage/base_background.png") no-repeat;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  top: -10vh;
  left: 40%;
  transform: translateX(-50%);
  width: 50vw;
  height: 60vh;
  /* max-width: 1200px;
  max-height: 600px; */
  z-index: 2;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.home_first_wrapper::after {
  background: url("@/assets/images/home/firstPage/first_animate.png") no-repeat;
  content: "";
  position: absolute;
  background-position: top center;
  background-size: cover;
  top: 15vh;
  left: 0;
  width: 100%;
  height: 90vh;
  z-index: 2;
  opacity: 0;
  /* 처음에 안 보이게 */
  animation: fadeInDown 1.5s ease-out 0.5s forwards;
}

.home_first_container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
  row-gap: 35px;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.home_first_container::before {
  background: url("@/assets/images/home/firstPage/second_animate_1.png") no-repeat;
  content: "";
  position: absolute;
  background-position: top center;
  background-size: contain;
  top: 0;
  left: 0;
  width: 30vh;
  height: 60vh;
  max-width: 600px;
  max-height: 1200px;
  opacity: 0;
  visibility: visible;
  display: block;
  z-index: 15;
  animation: slideInLeft 1.5s ease-out 0.8s forwards;
}

@keyframes slideInRight {
  0% {
    transform: translateX(100px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.home_first_container::after {
  background: url("@/assets/images/home/firstPage/second_animate_2.png") no-repeat;
  content: "";
  position: absolute;
  background-position: top right;
  background-size: contain;
  top: 7vh;
  right: 20vh;
  width: 150px;
  height: 100%;
  opacity: 0;
  visibility: visible;
  display: block;
  z-index: 15;
  animation: slideInRight 1.5s ease-out 0.8s forwards;
}

@keyframes zoomInFade {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.home_first_content_text {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 16px;
  top: 38vh;
  transform: translateY(-50%);
  line-height: 1.5;
  font-size: 20px;
  opacity: 0;
  animation: zoomInFade 1.5s ease-out 0.8s forwards;
}

@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  60% {
    opacity: 1;
    transform: translateY(-5px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.home_first_university_search_wrapper {
  position: absolute;
  top: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  margin: 0 auto;
  opacity: 0;
  animation: bounceInUp 1.5s ease-out 1.5s forwards;
  /* 1.2초 후 실행 */
}

.home_first_university_search {
  width: 100%;
}

.home_first_university_search_title {
  color: var(--brand-1);
  font-size: 16px;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  margin-right: 16px;
}

.home_first_university_search_input {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.home_first_main_total_input_wrapper_box {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.home_first_university_search_input_form {
  width: 100%;
  height: 48px;
  margin: 12px 0;
  border: 3px solid var(--brand-1);
  border-radius: 8px;
  font-size: 16px;
  padding: 8px 15px;
  color: var(--gray-5);
}

.home_first_search_icon {
  display: flex;
  align-items: center;
  justify-items: center;
}

.home_first_university_search_list {
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding-left: 160px;
}

.home_first_university_search_list_item {
  background-color: rgb(2, 76, 140, 0.2);
  border-radius: 4px;
  padding: 6px 6px;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

@media screen and (max-width: 1280px) {
  .home_first_wrapper {
    height: 800px;
  }

  .home_first_wrapper::after {
    top: 0;
    height: 100%;
  }

  .home_first_content_text {
    position: static;
  }

  .home_first_university_search_wrapper {
    position: static;
    animation: none;
    opacity: 1;
  }

  .home_first_university_search_input {}

  .home_first_wrapper::before {
    display: none;
  }

  .home_first_container {
    flex-direction: column;
    flex-wrap: nowrap;
    top: 200px;
    justify-content: initial;
  }

  .home_first_container::before {
    display: none;
  }

  .home_first_container::after {
    display: none;
  }


  .home_first_university_search_wrapper {
    width: 50%;
    padding: 0 24px;
  }

  .home_first_university_search_input {
    flex-direction: column;
    align-items: normal;
  }

  .home_first_university_search_list {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .home_first_wrapper {
    height: 580px;
  }

  .home_first_wrapper::after {
    top: auto;
    bottom: 0;
    height: 100%;
    animation: none;
    background-image: url("@/assets/images/home/firstPage/main_first_section_mobile_bg.png");
    opacity: 1;
  }

  .home_first_container {
    align-items: center;
    justify-content: initial;
    top: 130px;
    height: auto;
  }

  .home_first_content_text {
    position: static;
    width: 276px;
    font-size: 14px;
    opacity: 1;
    animation: none;
    transform: none;
  }

  .home_first_university_search_wrapper {
    width: 100%;
    padding: 0 24px;
  }
}