.switchBgBaseStyle {
    cursor: pointer;
    position: relative;
    border: none;
    border-radius: 16px;
    padding: 4px;
    height: 32px;
    width: 56px;
}
.switchCircle {
    background-color: white;
    position: absolute;
    top: 0;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    transition: all ease-in-out .1s;
}
.defaultActive {
    background-color: var(--brand-1);
}
.defaultDisabledBgColor {
    background-color: #AAAFB9;
}
.switchCircleWrap {
    position: relative;
    width: 100%;
    height: 100%;
}