.main_top_wrapper {
    max-width: 1100px;
    /*border: 1px solid red;*/
    margin: 0 auto 56px;
    padding-top: 100px;
    padding-bottom: 60px;
}

.main_top_inner {
    display: flex;
    gap: 24px;
    /*justify-content: space-between;*/
}

.scout_des_wrapper {}

.scout_description {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 16px;
    color: var(--gray-1);
    margin-top: 40px;
}

.scout_description .title {
    font-size: 24px;
    font-weight: 700;
}

.scout_content_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    /*justify-content: space-between;*/
    align-items: flex-start;
}

.scout_content_wrapper .title {
    font-size: 20px;
    font-weight: 600;
}

.scout_content_wrapper .nav_btn {
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 8px;
    color: var(--brand-1);
    font-weight: 500;
}

.scout_content_wrapper .nav_btn img {
    width: 8px;
    height: 12px;
    display: block;
}

.content_top_box {
    width: 438px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.home_second_program_card .card_box {
    width: 438px;
    height: 288px;
    padding: 26px 34px;
    border-radius: 20px;
    box-sizing: border-box;
    box-shadow: 0 4px 30px 0 rgba(174, 193, 208, 0.20);
    background: url("@/assets/svg/main/main_card_bg.svg") no-repeat #fff bottom right 10%;
    position: relative;
    cursor: pointer;
}


/* renewal */
@media screen and (max-width: 1280px) {
    .main_top_wrapper {
        width: 90%;
        margin: 0 auto 80px;
    }

    .main_top_inner {
        display: initial;
    }


    .scout_des_wrapper img {
        width: 100%;
    }

    .content_top_box {
        width: 100%;
        margin-top: 60px;
    }

    .home_second_program_card .card_box {
        width: 600px;
    }
}

@media screen and (max-width: 640px) {
    .main_top_wrapper {
        width: 90%;
        margin: 0 auto 80px;
    }

    .main_top_inner {
        display: initial;
    }

    .scout_des_wrapper {}

    .scout_des_wrapper img {
        width: 100%;
    }

    .content_top_box {
        width: 100%;
        margin-top: 60px;
    }

    .home_second_program_card .card_box {
        width: 320px;
        height: auto;
    }

    .home_second_program_card .card_title {
        display: -webkit-box !important;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}